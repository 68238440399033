<template>
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-bind="$attrs"
  >
    <g clip-path="url(#clip0_149_3613)">
      <path
        d="M12.6 1.875H9.82551L9.52218 0.96125C9.33116 0.38625 8.7976 0 8.19405 0H5.80596C5.2024 0 4.66884 0.38625 4.47751 0.96125L4.17449 1.875H1.4C0.628133 1.875 0 2.50594 0 3.28125V4.21875C0 4.74094 0.467911 4.6875 0.970044 4.6875H13.5333C13.7912 4.6875 14 4.47781 14 4.21875V3.28125C14 2.50594 13.3719 1.875 12.6 1.875ZM5.15853 1.875L5.36293 1.25781C5.42671 1.06594 5.60498 0.9375 5.80596 0.9375H8.19405C8.39502 0.9375 8.57329 1.06625 8.63645 1.25781L8.84116 1.875H5.15853Z"
        fill="currentColor"
      />
      <path
        d="M1.04749 5.6875L1.79166 14.7209C1.85762 15.4503 2.45713 16 3.18575 16H10.8145C11.5431 16 12.1426 15.4503 12.2089 14.7169L12.9525 5.6875H1.04749ZM4.66664 13.6562C4.66664 14.2741 3.73331 14.2766 3.73331 13.6562V7.09375C3.73331 6.47594 4.66664 6.47344 4.66664 7.09375V13.6562ZM7.46664 13.6562C7.46664 14.2741 6.53331 14.2766 6.53331 13.6562V7.09375C6.53331 6.47594 7.46664 6.47344 7.46664 7.09375V13.6562ZM10.2666 13.6562C10.2666 14.2741 9.33331 14.2766 9.33331 13.6562V7.09375C9.33331 6.83469 9.54206 6.625 9.79997 6.625C10.0579 6.625 10.2666 6.83469 10.2666 7.09375V13.6562Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_149_3613">
        <rect width="14" height="16" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconTrash'
}
</script>
