<template>
  <svg
    width="14"
    height="17"
    viewBox="0 0 14 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5 5.94562H11.2019V4.20188C11.2019 3.08747 10.7592 2.01871 9.97117 1.2307C9.18317 0.442696 8.11441 0 7 0C5.88559 0 4.81683 0.442696 4.02883 1.2307C3.24082 2.01871 2.79812 3.08747 2.79812 4.20188V5.94562H2.5C1.90326 5.94562 1.33097 6.18268 0.90901 6.60464C0.487053 7.02659 0.25 7.59889 0.25 8.19563V14.625C0.25 15.2217 0.487053 15.794 0.90901 16.216C1.33097 16.6379 1.90326 16.875 2.5 16.875H11.5C12.0967 16.875 12.669 16.6379 13.091 16.216C13.5129 15.794 13.75 15.2217 13.75 14.625V8.19563C13.75 7.59889 13.5129 7.02659 13.091 6.60464C12.669 6.18268 12.0967 5.94562 11.5 5.94562ZM3.92312 4.20188C3.92312 3.79781 4.00271 3.39771 4.15734 3.02441C4.31197 2.6511 4.53861 2.31191 4.82432 2.0262C5.11003 1.74048 5.44923 1.51384 5.82253 1.35921C6.19583 1.20459 6.59594 1.125 7 1.125C7.40406 1.125 7.80417 1.20459 8.17747 1.35921C8.55077 1.51384 8.88997 1.74048 9.17568 2.0262C9.46139 2.31191 9.68804 2.6511 9.84266 3.02441C9.99729 3.39771 10.0769 3.79781 10.0769 4.20188V5.94562H3.92312V4.20188ZM12.625 14.625C12.625 14.9234 12.5065 15.2095 12.2955 15.4205C12.0845 15.6315 11.7984 15.75 11.5 15.75H2.5C2.20163 15.75 1.91548 15.6315 1.7045 15.4205C1.49353 15.2095 1.375 14.9234 1.375 14.625V8.19563C1.375 7.89726 1.49353 7.61111 1.7045 7.40013C1.91548 7.18915 2.20163 7.07062 2.5 7.07062H11.5C11.7984 7.07062 12.0845 7.18915 12.2955 7.40013C12.5065 7.61111 12.625 7.89726 12.625 8.19563V14.625Z"
      fill="currentColor"
    />
    <path
      d="M7 9.52051C6.78177 9.52057 6.56852 9.58579 6.38759 9.70782C6.20666 9.82985 6.06629 10.0031 5.98447 10.2054C5.90265 10.4078 5.8831 10.6299 5.92834 10.8434C5.97358 11.0569 6.08154 11.252 6.23838 11.4038V12.7374C6.23838 12.8866 6.29764 13.0297 6.40313 13.1352C6.50862 13.2407 6.65169 13.2999 6.80088 13.2999H7.19913C7.34831 13.2999 7.49139 13.2407 7.59688 13.1352C7.70237 13.0297 7.76163 12.8866 7.76163 12.7374V11.4038C7.91847 11.252 8.02643 11.0569 8.07167 10.8434C8.1169 10.6299 8.09736 10.4078 8.01554 10.2054C7.93372 10.0031 7.79335 9.82985 7.61242 9.70782C7.43149 9.58579 7.21824 9.52057 7 9.52051Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconPadLock'
}
</script>
