<template>
  <svg
    width="17"
    height="13"
    viewBox="0 0 17 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.3425 5.20146H13.1973L10.2179 2.20348C9.70787 1.68358 9.71668 0.860267 10.2535 0.366359C10.7903 -0.136078 11.6405 -0.119019 12.1505 0.400884L16.0786 4.35215C16.6691 4.92404 17 5.68683 17 6.50121C17 7.31559 16.6779 8.06945 16.0962 8.62429L12.1505 12.6015C11.8909 12.87 11.5331 13 11.1842 13C10.8352 13 10.5131 12.8786 10.2535 12.6361C9.71668 12.1422 9.69865 11.3188 10.2179 10.7989L13.1973 7.80097H1.34208C0.599323 7.80097 0 7.22055 0 6.50121C0 5.78188 0.599743 5.20146 1.3425 5.20146Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconSidebarArrow'
}
</script>
