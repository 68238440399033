<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1476_2772)">
      <path
        d="M8.55557 0V1.55557H11.3439L3.69833 9.2011L4.7989 10.3017L12.4444 2.6561V5.44443H14V0H8.55557Z"
        fill="currentColor"
      />
      <path
        d="M12.4444 12.4444H1.55557V1.55557H7V0H1.55557C0.696099 0 0 0.696099 0 1.55557V12.4445C0 13.3039 0.696099 14 1.55557 14H12.4445C13.3039 14 14 13.3039 14 12.4444V7H12.4444V12.4444Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1476_2772">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconExternalLink'
}
</script>
