<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 15C9.05228 15 9.5 14.5523 9.5 14C9.5 13.4477 9.05228 13 8.5 13C7.94772 13 7.5 13.4477 7.5 14C7.5 14.5523 7.94772 15 8.5 15Z"
      fill="currentColor"
    />
    <path
      d="M8.5 19C9.05228 19 9.5 18.5523 9.5 18C9.5 17.4477 9.05228 17 8.5 17C7.94772 17 7.5 17.4477 7.5 18C7.5 18.5523 7.94772 19 8.5 19Z"
      fill="currentColor"
    />
    <path
      d="M12.5 15C13.0523 15 13.5 14.5523 13.5 14C13.5 13.4477 13.0523 13 12.5 13C11.9477 13 11.5 13.4477 11.5 14C11.5 14.5523 11.9477 15 12.5 15Z"
      fill="currentColor"
    />
    <path
      d="M12.5 19C13.0523 19 13.5 18.5523 13.5 18C13.5 17.4477 13.0523 17 12.5 17C11.9477 17 11.5 17.4477 11.5 18C11.5 18.5523 11.9477 19 12.5 19Z"
      fill="currentColor"
    />
    <path
      d="M16.5 15C17.0523 15 17.5 14.5523 17.5 14C17.5 13.4477 17.0523 13 16.5 13C15.9477 13 15.5 13.4477 15.5 14C15.5 14.5523 15.9477 15 16.5 15Z"
      fill="currentColor"
    />
    <path
      d="M16.5 19C17.0523 19 17.5 18.5523 17.5 18C17.5 17.4477 17.0523 17 16.5 17C15.9477 17 15.5 17.4477 15.5 18C15.5 18.5523 15.9477 19 16.5 19Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.25 7V20C23.25 20.995 22.855 21.948 22.152 22.652C21.448 23.355 20.495 23.75 19.5 23.75H5.5C4.505 23.75 3.552 23.355 2.848 22.652C2.145 21.948 1.75 20.995 1.75 20V7C1.75 6.005 2.145 5.052 2.848 4.348C3.552 3.645 4.505 3.25 5.5 3.25H19.5C20.495 3.25 21.448 3.645 22.152 4.348C22.855 5.052 23.25 6.005 23.25 7ZM21.75 7C21.75 6.403 21.513 5.831 21.091 5.409C20.669 4.987 20.097 4.75 19.5 4.75H5.5C4.903 4.75 4.331 4.987 3.909 5.409C3.487 5.831 3.25 6.403 3.25 7V20C3.25 20.597 3.487 21.169 3.909 21.591C4.331 22.013 4.903 22.25 5.5 22.25H19.5C20.097 22.25 20.669 22.013 21.091 21.591C21.513 21.169 21.75 20.597 21.75 20V7Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.5 8.75C22.914 8.75 23.25 9.086 23.25 9.5C23.25 9.914 22.914 10.25 22.5 10.25H2.5C2.086 10.25 1.75 9.914 1.75 9.5C1.75 9.086 2.086 8.75 2.5 8.75H22.5Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.75 2C15.75 1.586 16.086 1.25 16.5 1.25C16.914 1.25 17.25 1.586 17.25 2V6C17.25 6.414 16.914 6.75 16.5 6.75C16.086 6.75 15.75 6.414 15.75 6V2Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.75 2C7.75 1.586 8.086 1.25 8.5 1.25C8.914 1.25 9.25 1.586 9.25 2V6C9.25 6.414 8.914 6.75 8.5 6.75C8.086 6.75 7.75 6.414 7.75 6V2Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconCalendar'
}
</script>
