<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1036_784)">
      <path
        d="M26.128 6.11195L19.9391 12.3014C20.609 13.3713 20.9989 14.6412 20.9989 16.0011C20.9989 19.8607 17.8594 23.0004 14.0001 23.0004C12.6404 23.0004 11.3706 22.6105 10.3008 21.9405L5.2417 27.0001H22.9985C25.758 27.0001 27.9976 24.7603 27.9976 22.0005V10.0016C27.9976 8.42175 27.2678 7.02187 26.128 6.11195Z"
        fill="currentColor"
      />
      <path
        d="M26.2878 0.292474L21.5786 5.00205H20.8988C20.7888 4.4521 20.5888 3.93215 20.2989 3.46219C19.439 1.99232 17.8293 1.00241 15.9996 1.00241H12.0004C9.59079 1.00241 7.56115 2.72226 7.10123 5.00205H5.00161C2.2421 5.00205 0.00249955 7.24185 0.00249955 10.0016V22.0005C0.00249955 22.5305 0.0824853 23.0504 0.232458 23.5304C0.331191 23.8272 0.464292 24.1078 0.613016 24.3784C0.663007 24.4691 0.717997 24.5541 0.772987 24.6409C0.919836 24.8797 1.0998 25.094 1.26227 25.3202L0.292448 26.2902C-0.0974826 26.6801 -0.0974826 27.3201 0.292448 27.71C0.492412 27.9 0.742368 28 1.00232 28C1.26227 28 1.51223 27.9 1.71219 27.71L27.7076 1.71235C28.0975 1.32238 28.0975 0.682439 27.7076 0.292474C27.3176 -0.0974913 26.6777 -0.0974913 26.2878 0.292474ZM15.3798 11.2015C14.4862 10.9303 13.4426 10.9378 12.5503 11.2115C10.9605 11.7015 9.70077 12.9613 9.21086 14.5512C8.93715 15.4436 8.92966 16.4879 9.20086 17.3809L7.65051 18.9314C7.23933 18.0128 6.99875 17.0172 7.00125 16.0011C7.00125 12.1414 10.1407 9.0017 14 9.0017C15.0154 8.99982 16.0115 9.2398 16.9301 9.65101L15.3798 11.2015Z"
        fill="currentColor"
      />
      <path
        d="M18.9992 16.0011C18.9992 15.2011 18.8093 14.4412 18.4693 13.7713L11.7705 20.4707C12.4404 20.8106 13.2003 21.0006 14.0001 21.0006C16.7596 21.0006 18.9992 18.7608 18.9992 16.0011Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1036_784">
        <rect width="28" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconNoPhoto'
}
</script>
