<template>
  <svg
    width="22"
    height="26"
    viewBox="0 0 22 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.8627 17.4888C16.6516 17.4888 15.5605 18.0267 14.8032 18.8825L7.99171 14.5431C8.17356 14.0642 8.27442 13.5441 8.27442 13C8.27442 12.4557 8.17356 11.9356 7.99171 11.4569L14.8032 7.11731C15.5605 7.97305 16.6516 8.51122 17.8627 8.51122C20.1441 8.51122 22 6.60216 22 4.25551C22 1.90886 20.1441 0 17.8627 0C15.5813 0 13.7254 1.90906 13.7254 4.25571C13.7254 4.79982 13.8264 5.31993 14.0081 5.79878L7.1968 10.1382C6.43949 9.28246 5.34838 8.74429 4.13731 8.74429C1.85594 8.74429 0 10.6535 0 13C0 15.3466 1.85594 17.2557 4.13731 17.2557C5.34838 17.2557 6.43949 16.7177 7.1968 15.8618L14.0081 20.2012C13.8264 20.6801 13.7254 21.2002 13.7254 21.7445C13.7254 24.0909 15.5813 26 17.8627 26C20.1441 26 22 24.0909 22 21.7445C22 19.3978 20.1441 17.4888 17.8627 17.4888ZM15.234 4.25571C15.234 2.7648 16.4133 1.5518 17.8627 1.5518C19.3121 1.5518 20.4914 2.7648 20.4914 4.25571C20.4914 5.74661 19.3121 6.95961 17.8627 6.95961C16.4133 6.95961 15.234 5.74661 15.234 4.25571ZM4.13731 15.7039C2.68769 15.7039 1.50844 14.4909 1.50844 13C1.50844 11.5091 2.68769 10.2961 4.13731 10.2961C5.58673 10.2961 6.76579 11.5091 6.76579 13C6.76579 14.4909 5.58673 15.7039 4.13731 15.7039ZM15.234 21.7443C15.234 20.2534 16.4133 19.0404 17.8627 19.0404C19.3121 19.0404 20.4914 20.2534 20.4914 21.7443C20.4914 23.2352 19.3121 24.4482 17.8627 24.4482C16.4133 24.4482 15.234 23.2352 15.234 21.7443Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconShare'
}
</script>
