<template>
  <svg
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.7445 2.53223V5.57178C17.25 7.59572 18.4511 12.0782 16.4272 15.5832C14.4033 19.0887 9.92079 20.2899 6.4153 18.2659C2.9098 16.242 1.70867 11.7595 3.7326 8.25447C4.3759 7.14051 5.30091 6.21508 6.4153 5.57178V2.53223C1.23113 4.55573 -1.33132 10.3995 0.69262 15.5841C2.71656 20.7687 8.55991 23.3311 13.7445 21.3072C18.9291 19.2833 21.4915 13.4399 19.4676 8.25533C18.4434 5.6319 16.3684 3.55643 13.7445 2.53223Z"
      fill="currentColor"
    />
    <path d="M11.4545 0H8.70605V7.3292H11.4545V0Z" fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'IconTurnOff'
}
</script>
