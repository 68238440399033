<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="12"
    viewBox="0 0 19 12"
    fill="none"
  >
    <g clip-path="url(#clip0_140_1834)">
      <path
        d="M9.37 0C5.79 0 2.54 1.96 0.15 5.14C-0.05 5.4 -0.05 5.76 0.15 6.03C2.55 9.22 5.79 11.18 9.37 11.18C12.95 11.18 16.2 9.22 18.59 6.04C18.79 5.78 18.79 5.42 18.59 5.15C16.2 1.96 12.95 0 9.37 0ZM9.63 9.52C7.25 9.67 5.29 7.71 5.44 5.33C5.56 3.37 7.15 1.78 9.12 1.65C11.5 1.5 13.46 3.46 13.31 5.84C13.18 7.8 11.59 9.39 9.63 9.52ZM9.51 7.7C8.23 7.78 7.17 6.73 7.26 5.45C7.33 4.39 8.18 3.54 9.24 3.47C10.52 3.39 11.58 4.44 11.49 5.72C11.42 6.78 10.56 7.64 9.51 7.7Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_140_1834">
        <rect width="18.74" height="11.17" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconEye'
}
</script>
