<template>
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1273_2498)">
      <path
        d="M11.25 1.25C12.6287 1.25 13.75 2.37125 13.75 3.75V11.25C13.75 12.6287 12.6287 13.75 11.25 13.75H3.75C2.37125 13.75 1.25 12.6287 1.25 11.25V3.75C1.25 2.37125 2.37125 1.25 3.75 1.25H11.25ZM11.25 0H3.75C1.67875 0 0 1.67875 0 3.75V11.25C0 13.3212 1.67875 15 3.75 15H11.25C13.3212 15 15 13.3212 15 11.25V3.75C15 1.67875 13.3212 0 11.25 0Z"
        fill="currentColor"
      />
      <path
        d="M10.625 8.125H4.375C4.03 8.125 3.75 7.845 3.75 7.5C3.75 7.155 4.03 6.875 4.375 6.875H10.625C10.9706 6.875 11.25 7.155 11.25 7.5C11.25 7.845 10.9706 8.125 10.625 8.125Z"
        fill="currentColor"
      />
      <path
        d="M10.625 5H4.375C4.03 5 3.75 4.72 3.75 4.375C3.75 4.03 4.03 3.75 4.375 3.75H10.625C10.9706 3.75 11.25 4.03 11.25 4.375C11.25 4.72 10.9706 5 10.625 5Z"
        fill="currentColor"
      />
      <path
        d="M10.625 11.25H4.375C4.03 11.25 3.75 10.9706 3.75 10.625C3.75 10.2794 4.03 10 4.375 10H10.625C10.9706 10 11.25 10.2794 11.25 10.625C11.25 10.9706 10.9706 11.25 10.625 11.25Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1273_2498">
        <rect width="15" height="15" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconList'
}
</script>
