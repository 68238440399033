<template>
  <svg
    width="12"
    height="6"
    viewBox="0 0 12 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2041_3853)">
      <path
        d="M5.99623 0.000123453C6.21376 0.000123472 6.42403 0.0700383 6.5908 0.216225L11.7533 4.74165C12.0796 5.02767 12.0796 5.49801 11.7533 5.78402C11.427 6.07004 10.8905 6.07004 10.5642 5.78402L5.99623 1.77978L1.43551 5.78402C1.10922 6.07004 0.572669 6.07004 0.246386 5.78402C-0.0798988 5.498 -0.0798987 5.02767 0.246386 4.74165L5.40892 0.216225C5.57569 0.0700382 5.78596 0.000123435 6.00348 0.000123454L5.99623 0.000123453Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2041_3853">
        <rect
          width="12"
          height="6"
          fill="white"
          transform="translate(12 6) rotate(-180)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconSortUp'
}
</script>
