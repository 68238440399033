<template>
  <svg
    width="15"
    height="22"
    viewBox="0 0 15 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.83141 12.8498C2.92469 13.203 -0.0513213 16.5038 0.000670879 20.426V20.625C0.000670879 21.3842 0.616413 22 1.37567 22C2.13493 22 2.75067 21.3842 2.75067 20.625V20.371C2.70942 17.963 4.48661 15.9091 6.87567 15.6045C9.3975 15.3544 11.6448 17.1961 11.8948 19.7179C11.9095 19.867 11.9172 20.0165 11.9172 20.1665V20.625C11.9172 21.3842 12.5329 22 13.2922 22C14.0514 22 14.6672 21.3842 14.6672 20.625V20.1665C14.6629 16.112 11.3723 12.8287 7.31739 12.833C7.1554 12.833 6.99297 12.8386 6.83141 12.8498Z"
      fill="currentColor"
    />
    <path
      d="M7.33398 11C10.3714 11 12.834 8.53746 12.834 5.5C12.834 2.46254 10.3714 0 7.33398 0C4.29652 0 1.83398 2.46254 1.83398 5.5C1.83699 8.53617 4.29781 10.997 7.33398 11ZM7.33398 2.75C8.85293 2.75 10.084 3.98105 10.084 5.5C10.084 7.01894 8.85293 8.25 7.33398 8.25C5.81504 8.25 4.58398 7.01894 4.58398 5.5C4.58398 3.98105 5.81504 2.75 7.33398 2.75Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconUser'
}
</script>
