<template>
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 8.03528C0 15.9291 10.0709 26 17.9647 26C19.7178 26 21.3399 25.336 22.5484 24.1274L26 20.6758L19.1627 13.871L15.45 17.5837C12.2274 16.1901 9.91927 13.871 8.42729 10.5394L12.1399 6.82674L5.32419 0L1.8726 3.45159C0.66405 4.65973 0 6.28224 0 8.03528ZM17.9322 23.8114C11.149 23.8114 2.14505 14.8071 2.14505 8.0243C2.14505 6.85927 2.58056 5.78126 3.37555 4.98667L5.29166 3.07057L9.03685 6.81576L5.84673 10.0169L6.11877 10.6809C7.90435 15.1341 11.0074 18.2262 15.3187 19.8703L15.9827 20.1207L19.1729 16.9306L22.918 20.6758L21.0019 22.5919C20.2179 23.3869 19.1293 23.8224 17.9643 23.8224L17.9318 23.8114H17.9322Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconPhoneV2'
}
</script>
