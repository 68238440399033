<template>
  <div class="w-full border-none p-0 bg-transparent rounded-md">
    <label
      class="block text-base-gray-dark text-sm font-medium leading-21 mb-2 cursor-pointer"
      :class="{ 'text-red-500': error }"
      :for="id"
      v-if="label"
    >
      <span class="inline-block">
        <span>
          {{ label }}
        </span>
        <span v-if="required" class="ml-1 font-semibold">*</span>
      </span>
    </label>

    <div class="relative">
      <input
        :id="id"
        :name="id"
        :data-testid="id"
        class="w-full h-[45px] block px-3 py-2 appearance-none border rounded-md shadow-sm focus:outline-none text-sm focus:ring-1 focus:ring-black focus:border-black"
        :placeholder="placeholder"
        :class="{
          'text-red-700 border-red-500 bg-red-50 placeholder-red-700 focus:ring-red-500 focus:border-red-500':
            error,
          'bg-base-gray-mediumLight text-base-gray': disabled,
          'animate-pulse bg-gray-100': loading,
          'bg-white text-base-gray placeholder-[#B1B1B1]': !disabled && !loading
        }"
        :required="required"
        :value="modelValue"
        v-bind="$attrs"
        v-model="inputValue"
        @input="handleInput"
        :autocomplete="autocompleteOff"
        :disabled="disabled"
        :title="label"
        ref="input"
      />
    </div>
    <span
      role="alert"
      class="block text-sm text-red-600 font-normal leading-5"
      v-if="error"
    >
      {{ error }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'BaseInput',
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    error: {
      type: [String, Boolean],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inputValue: this.modelValue || ''
    }
  },
  methods: {
    handleInput() {
      this.$emit('input', this.inputValue)
    },
    focus() {
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    }
  },
  beforeMount() {
    if (this.id === undefined) {
      throw new Error('BaseInput requires an id')
    }
  },
  computed: {
    autocompleteOff() {
      return Math.random().toString(36).substring(2, 15)
    }
  }
}
</script>
