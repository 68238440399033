<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="36" viewBox="0 0 40 36" fill="none">
    <path d="M1.54 0C0.69 0 0 0.69 0 1.54C0 2.39 0.69 3.08 1.54 3.08H4.28C4.74 3.08 5.15 3.37 5.29 3.81L10.37 19.06C10.6 19.74 10.62 20.47 10.45 21.16L10.03 22.84C9.42 25.3 11.33 27.75 13.86 27.75H36.35C37.2 27.75 37.89 27.06 37.89 26.21C37.89 25.36 37.2 24.67 36.35 24.67H13.88C13.28 24.67 12.89 24.18 13.04 23.6L13.36 22.34C13.47 21.9 13.87 21.59 14.32 21.59H34.83C35.49 21.59 36.08 21.17 36.29 20.54L39.36 5.1C39.69 4.1 38.95 3.07 37.9 3.07H9.07C8.61 3.07 8.2 2.78 8.06 2.34L7.64 1.07C7.42 0.42 6.83 0 6.17 0H1.54ZM12.33 29.29C10.63 29.29 9.25 30.67 9.25 32.37C9.25 34.07 10.63 35.45 12.33 35.45C14.03 35.45 15.41 34.07 15.41 32.37C15.41 30.67 14.03 29.29 12.33 29.29ZM34.82 29.29C33.12 29.29 31.74 30.67 31.74 32.37C31.74 34.07 33.12 35.45 34.82 35.45C36.52 35.45 37.9 34.07 37.9 32.37C37.9 30.67 36.52 29.29 34.82 29.29Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconCart'
}
</script>
