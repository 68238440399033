<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
  >
    <path
      d="M14.3079 1.76C12.5179 -0.0199987 9.6179 1.07288e-06 7.8479 1.79C6.1179 -0.029999 3.2379 -0.109999 1.4179 1.62C-0.4021 3.35 -0.4821 6.23 1.2479 8.05C1.2879 8.1 1.3379 8.14 1.3879 8.19L7.4679 14.27C7.6779 14.48 8.0179 14.48 8.2279 14.27L14.3079 8.19C16.0679 6.41 16.0679 3.54 14.3079 1.76Z"
      fill="none"
      stroke="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconHeartOutlined'
}
</script>
