<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" viewBox="0 0 14 20" fill="none">
    <path d="M8.36 19.04C8.19 19.04 8.00999 19.04 7.83999 19.04C7.68 19.04 7.65999 18.99 7.72999 18.86C8.11999 18.12 8.50999 17.38 8.89999 16.63C9.75999 14.99 10.62 13.36 11.48 11.72C11.77 11.17 12.05 10.63 12.34 10.08C12.39 9.98001 12.39 9.90001 12.34 9.81001C11.35 7.93001 10.37 6.06001 9.37999 4.18001C9.06999 3.59001 8.76 2.99001 8.44 2.40001C8.42 2.35001 8.41 2.28001 8.33999 2.28001C8.27999 2.28001 8.26999 2.36001 8.24999 2.40001C7.03999 4.71001 5.81999 7.03001 4.60999 9.34001C4.58999 9.38001 4.56999 9.43001 4.53999 9.47001C4.26999 9.79001 4.33999 10.08 4.53999 10.44C5.23999 11.72 5.90999 13.02 6.58999 14.31C6.65999 14.43 6.65999 14.53 6.58999 14.65C6.40999 14.97 6.24999 15.29 6.07999 15.61C6.04999 15.66 6.03999 15.75 5.96999 15.74C5.89999 15.74 5.89 15.65 5.86 15.6C5.28 14.5 4.69999 13.4 4.11999 12.3C3.73999 11.57 3.34999 10.84 2.96999 10.11C2.89999 9.99001 2.91 9.89001 2.96999 9.77001C4.49999 6.86001 6.02999 3.96001 7.54999 1.05001C7.62999 0.900006 7.71999 0.850006 7.87999 0.850006C8.20999 0.860006 8.53 0.850006 8.86 0.850006C8.99 0.850006 9.06999 0.890006 9.13999 1.01001C10.16 2.96001 11.18 4.91001 12.21 6.85001C12.72 7.82001 13.22 8.78001 13.73 9.75001C13.8 9.88001 13.81 9.99001 13.73 10.13C12.2 13.04 10.67 15.95 9.13999 18.87C9.06999 19 8.99 19.05 8.85 19.04C8.68 19.03 8.52 19.04 8.35 19.04H8.36Z" fill="currentColor"/>
    <path d="M5.63 17.68C5.75 17.46 5.85 17.28 5.95 17.09C7.16 14.78 8.37 12.47 9.59 10.17C9.67 10.02 9.67 9.90001 9.59 9.74001C8.86 8.37001 8.14 6.99001 7.42 5.62001C7.35 5.48001 7.33 5.37001 7.42 5.23001C7.6 4.92001 7.75 4.60001 7.92 4.28001C7.95 4.23001 7.96 4.15001 8.03 4.15001C8.09 4.15001 8.1 4.23001 8.13 4.27001C8.59 5.14001 9.04 6.00001 9.5 6.87001C10 7.82001 10.5 8.78001 11.01 9.73001C11.09 9.88 11.1 10 11.01 10.16C9.48 13.07 7.95 15.97 6.43 18.88C6.37 19 6.29 19.05 6.16 19.04C5.82 19.04 5.47 19.04 5.12 19.04C4.99 19.04 4.92 18.99 4.86 18.88C4.11 17.45 3.37 16.03 2.62 14.61C1.84 13.12 1.06 11.63 0.269996 10.14C0.199996 10.01 0.189996 9.90001 0.269996 9.76001C1.81 6.84001 3.34 3.93001 4.87 1.01001C4.93 0.900006 5 0.850006 5.13 0.850006C5.47 0.850006 5.81 0.850006 6.15 0.850006C6.35 0.850006 6.36 0.870006 6.27 1.05001C5.85 1.85001 5.44 2.64001 5.02 3.44001C4.17 5.06001 3.32 6.68001 2.47 8.30001C2.21 8.80001 1.95 9.29001 1.69 9.79001C1.63 9.90001 1.63 9.99001 1.69 10.1C2.82 12.24 3.94 14.39 5.07 16.53C5.26 16.9 5.46 17.27 5.67 17.67L5.63 17.68Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconRenault'
}
</script>
