<template>
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.05471 1.31876C6.5151 0.17187 5.14786 -0.320757 4.00097 0.218846C3.51731 0.446307 3.12821 0.835403 2.90075 1.31907H0.988654C0.44287 1.31876 0 1.76163 0 2.30772C0 2.85381 0.44287 3.29668 0.988963 3.29668H2.89766C3.43726 4.44357 4.8042 4.9362 5.95139 4.39691C6.43506 4.16944 6.82415 3.78035 7.05162 3.29668H14.8348C15.3808 3.29668 15.8237 2.85381 15.8237 2.30772C15.8237 1.76163 15.3808 1.31876 14.8348 1.31876H7.05501H7.05471Z"
      fill="currentColor"
    />
    <path
      d="M10.8489 5.19238C9.96009 5.19393 9.15131 5.70726 8.77148 6.5111H0.988963C0.44287 6.5111 0 6.95397 0 7.50007C0 8.04616 0.44287 8.48903 0.988963 8.48903H8.7687C9.308 9.63592 10.6752 10.1285 11.8221 9.58925C12.3058 9.36179 12.6949 8.97269 12.9223 8.48903H14.8344C15.3805 8.48903 15.8234 8.04616 15.8234 7.50007C15.8234 6.95397 15.3805 6.5111 14.8344 6.5111H12.9257C12.5459 5.70757 11.7378 5.19424 10.8489 5.19238Z"
      fill="currentColor"
    />
    <path
      d="M4.97448 10.3843C4.08565 10.3861 3.27749 10.8995 2.89766 11.703H0.988963C0.44287 11.703 0 12.1459 0 12.692C0 13.2381 0.44287 13.6809 0.988963 13.6809H2.89766C3.43726 14.8278 4.8042 15.3204 5.95139 14.7811C6.43506 14.5537 6.82415 14.1646 7.05162 13.6809H14.8348C15.3808 13.6809 15.8237 13.2381 15.8237 12.692C15.8237 12.1459 15.3808 11.703 14.8348 11.703H7.05501C6.67488 10.8982 5.86486 10.3849 4.97479 10.3843H4.97448Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconFilters'
}
</script>
