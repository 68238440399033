<template>
  <svg
    width="66"
    height="66"
    viewBox="0 0 66 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M64.7995 60.9392L48.5063 44.646C58.0642 32.9564 56.3356 15.733 44.646 6.17511C32.9577 -3.38279 15.733 -1.65418 6.17511 10.0354C-3.38279 21.7249 -1.65418 38.9484 10.0354 48.5063C20.1038 56.7386 34.5788 56.7386 44.6473 48.5063L60.9405 64.7995C62.0242 65.8461 63.7528 65.8167 64.8008 64.7329C65.8218 63.6748 65.8218 61.9986 64.8008 60.9405L64.7995 60.9392ZM27.3842 49.2215C15.3236 49.2215 5.54687 39.4448 5.54687 27.3842C5.54687 15.3236 15.3249 5.54815 27.3842 5.54815C39.4435 5.54815 49.2215 15.3249 49.2215 27.3855C49.2074 39.4397 39.4397 49.2087 27.3842 49.2228V49.2215Z"
      fill="currentColor"
      fill-opacity="0.59"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconMagnifyingGlass'
}
</script>
