<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.11295 16.225C9.72385 17.8359 12.3355 17.8359 13.9464 16.225L16.8906 13.2808C17.4367 12.7531 17.4518 11.8826 16.9246 11.3364C16.3973 10.7903 15.5263 10.7753 14.9802 11.3025C14.9686 11.3137 14.9574 11.3248 14.9463 11.3364L12.3952 13.8866L12.3742 1.375C12.3742 0.615742 11.7584 0 10.9992 0C10.2399 0 9.62416 0.615742 9.62416 1.375L9.6435 13.8664L7.11264 11.3356C6.5665 10.8079 5.69596 10.823 5.1683 11.3695C4.65354 11.9023 4.65354 12.7471 5.1683 13.2799L8.11252 16.225H8.11295Z"
      fill="currentColor"
    />
    <path
      d="M20.625 13.2915C19.8657 13.2915 19.25 13.9072 19.25 14.6665V18.8749C19.2496 19.0815 19.082 19.2491 18.8749 19.25H3.12469C2.91801 19.2496 2.75043 19.082 2.75 18.8749V14.6665C2.75 13.9072 2.13426 13.2915 1.375 13.2915C0.615742 13.2915 0 13.9072 0 14.6665V18.8749C0.00214844 20.6001 1.39992 21.9978 3.12469 22H18.8749C20.6001 21.9978 21.9979 20.6001 22 18.8749V14.6665C22 13.9072 21.3843 13.2915 20.625 13.2915Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconDownloads'
}
</script>
