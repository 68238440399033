import { post } from '@/config/api'

// client login
const login = async (email, password) => {
  const payload = {
    email,
    password
  }

  try {
    return await post('/api/client-login', payload)
  } catch (e) {
    return e?.response
  }
}

const requestAuthorization = async (deviceId, clientId) => {
  const payload = {
    id: deviceId,
    clientId: clientId
  }

  try {
    return await post('/api/devices/notification', payload)
  } catch (e) {
    return {status: e?.response?.status}
  }
}

const sendResetLink = async (email) => {
  const payload = {
    email
  }

  try {
    const { status, data } = await post('/api/password-link', payload)

    return { status, data }
  } catch (e) {
    return e
  }
}

const resetPassword = async (
  token,
  password,
  passwordConfirmation,
  emailHash
) => {
  const payload = {
    token,
    password,
    password_confirmation: passwordConfirmation,
    emailHash
  }

  try {
    const { status, data } = await post('/api/donor/password-reset', payload)

    return { status, data }
  } catch (e) {
    const data = e.response.data
    const status = e.response.status

    return { data, status }
  }
}

const logout = async (token) => {
  try {
    return await post('/api/auth/logout', { token })
  } catch (e) {
    return null
  }
}

export {
  login,
  logout,
  sendResetLink,
  requestAuthorization,
  resetPassword
}
