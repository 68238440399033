import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '@/views/LoginView.vue'
import RegisterView from '@/views/RegisterView.vue'
import { useStorage } from 'vue3-storage'

const storage = useStorage()
const routes = [
  {
    path: '/',
    name: 'home',
    redirect: () => {
      const isAuthenticated = !!storage.getStorageSync('loggedUser')

      return isAuthenticated ? { name: 'catalog' } : { name: 'login' }
    }
  },
  {
    path: '/catalogo-clasico',
    name: 'catalog-clasic',
    component: () => import('@/views/CatalogView.vue')
  },
  {
    path: '/catalogo',
    name: 'catalog',
    component: () => import('@/views/CatalogV2View.vue'),
    meta: { layout: 'sidebar' }
  },
  {
    path: '/catalogo-cards',
    name: 'catalog-cards',
    component: () => import('@/views/CatalogV3View.vue'),
    meta: { layout: 'sidebar' }
  },
  {
    path: '/pedidos',
    name: 'orders',
    component: () => import('@/views/OrdersView.vue'),
    meta: { layout: 'sidebar' }
  },
  {
    path: '/carrito',
    name: 'cart',
    component: () => import('@/views/CartView.vue'),
    meta: { layout: 'sidebar' }
  },
  {
    path: '/producto/:id',
    name: 'product',
    component: () => import('@/views/ProductView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: { layout: 'login' }
  },
  {
    path: '/registro',
    name: 'register',
    component: RegisterView,
    meta: { layout: 'login' }
  },
  {
    path: '/perfil',
    name: 'profile',
    component: () => import('@/views/ProfileView.vue'),
    meta: { layout: 'sidebar' }
  },
  {
    path: '/descargas',
    name: 'downloads',
    component: () => import('@/views/DownloadsView.vue'),
    meta: { layout: 'sidebar' }
  },
  {
    path: '/reclamos',
    name: 'claims',
    component: () => import('@/views/ClaimsView.vue'),
    meta: { layout: 'sidebar' }
  },
  {
    path: '/cuenta-corriente',
    name: 'account',
    component: () => import('@/views/AccountView.vue'),
    meta: { layout: 'sidebar' }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('@/views/NotFoundView.vue')
  }
]

const baseUrl = process.env.VITE_BASE_URL || '/'
const router = createRouter({
  history: createWebHistory(baseUrl),
  routes
})
const isAuthenticated = !!storage.getStorageSync('loggedUser')

router.beforeEach((to, from, next) => {
  const allowedRoutes = ['login', 'register', 'forgotPassword', 'resetPassword']

  if (!allowedRoutes.includes(to.name) && !isAuthenticated) {
    next({ name: 'login' })
  } else {
    next()
  }
})

export default router
