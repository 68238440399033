<template>
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.2122 3.77859C19.7679 1.34317 16.5175 0.0016266 13.0545 0C5.91865 0 0.111549 5.77931 0.108689 12.8827C0.107872 15.1534 0.703619 17.3701 1.83668 19.324L0 26L6.86294 24.2083C8.75397 25.2347 10.8828 25.7759 13.0492 25.7763H13.0545C20.1892 25.7763 25.9975 19.9966 26 12.8928C26.0016 9.45015 24.6561 6.21321 22.2118 3.77819L22.2122 3.77859ZM13.0545 23.6008H13.0501C11.1194 23.6 9.22591 23.0835 7.57351 22.108L7.18084 21.8758L3.10826 22.9391L4.19516 18.9877L3.93937 18.5827C2.86228 16.8776 2.2935 14.907 2.29432 12.8839C2.29636 6.97975 7.12363 2.17639 13.0586 2.17639C15.9328 2.17721 18.6345 3.29265 20.6656 5.31695C22.6968 7.34126 23.8152 10.0317 23.8144 12.8928C23.8119 18.7974 18.9851 23.6016 13.0541 23.6016L13.0545 23.6008ZM18.9564 15.5808C18.6332 15.4198 17.0425 14.641 16.7459 14.5333C16.4497 14.4259 16.2339 14.3722 16.0182 14.6943C15.8024 15.0164 15.1826 15.7418 14.9938 15.9566C14.805 16.1713 14.6166 16.1985 14.293 16.0371C13.9694 15.876 12.9275 15.5361 11.6918 14.4393C10.7304 13.5858 10.0811 12.5313 9.89235 12.2093C9.70398 11.8868 9.89071 11.7294 10.0341 11.5521C10.3843 11.1194 10.7349 10.6656 10.8428 10.4509C10.9506 10.2362 10.8967 10.0479 10.8158 9.88689C10.7353 9.72586 10.0881 8.14114 9.8188 7.4966C9.55606 6.86914 9.28965 6.95372 9.09107 6.94396C8.9027 6.93461 8.68696 6.93257 8.47122 6.93257C8.25547 6.93257 7.9053 7.01309 7.60865 7.33556C7.31241 7.65804 6.47681 8.43677 6.47681 10.0211C6.47681 11.6054 7.63603 13.1364 7.79742 13.3511C7.95882 13.5659 10.0783 16.8174 13.323 18.2118C14.0949 18.5437 14.6971 18.7417 15.167 18.8901C15.9422 19.1353 16.647 19.1004 17.2043 19.0178C17.8258 18.9251 19.1178 18.2391 19.3879 17.4872C19.6576 16.7353 19.6576 16.0907 19.5763 15.9561C19.4958 15.8219 19.2801 15.7414 18.9564 15.5804V15.5808Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconWhatsappV2'
}
</script>
