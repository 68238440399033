<template>
  <svg width="24" height="7" viewBox="0 0 24 7" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind="$attrs">
    <path d="M22.4545 0.363525H1.54545C0.968155 0.363525 0.5 0.83161 0.5 1.40898V5.5908C0.5 6.16817 0.968155 6.63625 1.54545 6.63625H22.4545C23.0319 6.63625 23.5 6.16817 23.5 5.5908V1.40898C23.5 0.83161 23.0319 0.363525 22.4545 0.363525Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconMinus'
}
</script>