<template>
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4578 2.83317C13.4578 1.26834 12.1891 0 10.6246 0H6.37468C4.80984 0 3.5415 1.26867 3.5415 2.83317V3.54139H13.4581V2.83317H13.4578Z"
      fill="currentColor"
    />
    <path
      d="M11.3331 10.6248H5.66647C4.49288 10.6248 3.5415 11.5761 3.5415 12.7497V14.8747C3.5415 16.0483 4.49288 16.9996 5.66647 16.9996H11.3331C12.5067 16.9996 13.4581 16.0483 13.4581 14.8747V12.7497C13.4581 11.5761 12.5067 10.6248 11.3331 10.6248Z"
      fill="currentColor"
    />
    <path
      d="M13.4578 4.95801H3.54147C1.58651 4.96033 0.00208004 6.54476 -0.000244141 8.49973V11.3329C0.00174801 12.7394 0.835464 14.0117 2.12472 14.5741V12.7497C2.12704 10.7947 3.71147 9.21026 5.66644 9.20794H11.3331C13.2881 9.21026 14.8725 10.7947 14.8748 12.7497V14.5741C16.1641 14.0117 16.9978 12.7394 16.9998 11.3329V8.49973C16.9975 6.54476 15.413 4.96033 13.4581 4.95801H13.4578ZM12.7495 7.79151H11.3328C10.9417 7.79151 10.6246 7.47443 10.6246 7.0833C10.6246 6.69218 10.9417 6.37509 11.3328 6.37509H12.7495C13.1407 6.37509 13.4578 6.69218 13.4578 7.0833C13.4578 7.47443 13.1407 7.79151 12.7495 7.79151Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconPrinter'
}
</script>
