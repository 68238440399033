<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
    <path d="M0.98999 29.1L2.95998 21.95C1.67998 19.73 0.98999 17.27 0.98999 14.71C0.98999 6.92002 7.4 0.52002 15.23 0.52002C23.06 0.52002 29.57 6.93002 29.57 14.71C29.57 22.49 23.06 29.1 15.23 29.1C12.77 29.1 10.4 28.31 8.27998 27.13L0.98999 29.1Z" fill="#ECECEC"/>
    <path d="M8.67998 24.56L9.11998 24.81C10.94 25.94 13.06 26.63 15.28 26.63C21.74 26.63 27.11 21.26 27.11 14.7C27.11 8.13997 21.74 2.96997 15.23 2.96997C8.72 2.96997 3.45 8.18997 3.45 14.7C3.45 16.97 4.08997 19.18 5.26997 21.06L5.56999 21.5L4.43999 25.64L8.67998 24.56Z" fill="#55CC6C"/>
    <path d="M11.44 8.10005L10.5 8.05005C10.2 8.05005 9.90998 8.15005 9.70998 8.35005C9.26998 8.74005 8.52998 9.48005 8.32998 10.47C7.98998 11.95 8.52999 13.72 9.80999 15.5C11.09 17.2701 13.56 20.1301 17.89 21.3601C19.27 21.7501 20.35 21.51 21.24 20.97C21.93 20.53 22.37 19.84 22.52 19.1L22.67 18.41C22.72 18.21 22.62 17.9701 22.42 17.8701L19.32 16.4401C19.12 16.34 18.88 16.39 18.73 16.59L17.5 18.17C17.4 18.2701 17.25 18.3201 17.11 18.2701C16.27 17.97 13.46 16.79 11.94 13.83C11.89 13.68 11.89 13.5301 11.99 13.4401L13.17 12.1101C13.27 11.9601 13.32 11.7701 13.27 11.6201L11.84 8.42005C11.79 8.27005 11.64 8.12005 11.45 8.12005" fill="#FDFDFD"/>
  </svg>
</template>

<script>
export default {
  name: 'IconWhatsapp'
}
</script>
