<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="32" viewBox="0 0 24 32" fill="none">
    <path d="M12.12 0.219971C5.61996 0.219971 0.329956 5.54997 0.329956 12.1C0.329956 21.41 11.01 31.01 11.47 31.42C11.66 31.59 11.89 31.67 12.12 31.67C12.35 31.67 12.59 31.59 12.77 31.42C13.22 31.02 23.91 21.41 23.91 12.1C23.91 5.54997 18.62 0.219971 12.12 0.219971ZM12.12 18.56C8.50996 18.56 5.56998 15.62 5.56998 12.01C5.56998 8.39997 8.50996 5.45997 12.12 5.45997C15.73 5.45997 18.67 8.39997 18.67 12.01C18.67 15.62 15.73 18.56 12.12 18.56Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'IconInstagram'
}
</script>
