<template>
  <div class="relative">
    <loading-indicator v-if="updatingCart" />
    <div
      class="fixed lg:inset-y-0 lg:flex lg:flex-col transition-all duration-300 bg-white border-r border-gray-200 h-screen"
      :class="[isCollapsed ? 'w-24' : 'w-72', sidebarZIndex]"
    >
      <button
        @click="toggleSidebar"
        class="absolute top-10 flex justify-center items-center bg-white border-2 border-base-gray-lighter p-2 rounded-full transition-all w-[37px] h-[37px] cursor-pointer outline-none focus:outline-none"
        :class="{ 'left-[74px]': isCollapsed, 'left-[268px]': !isCollapsed }"
      >
        <base-icon
          name="sidebar-arrow"
          class="text-base-gray-light cursor-pointer"
          :class="{
            'transform rotate-180': !isCollapsed,
            'transform rotate-0': isCollapsed
          }"
        />
      </button>
      <div class="flex grow flex-col gap-y-5 border-r border-gray-200 bg-white">
        <div class="flex h-16 shrink-0 items-center px-6">
          <transition name="slide-fade">
            <router-link
              to="/"
              class="flex items-center text-base-gray-dark text-sm font-normal leading-normal outline-none focus-visible:outline-none h-auto w-full px-2 py-9"
              v-show="!isCollapsed"
            >
              <base-icon
                name="julmar"
                class="text-base-gray-dark max-w-[125px] h-auto"
              />
            </router-link>
          </transition>
        </div>
        <nav class="flex flex-1 flex-col justify-between px-6">
          <div>
            <ul role="list" class="flex flex-1 flex-col">
              <li>
                <router-link
                  to="/catalogo"
                  class="flex justify-between items-center w-full max-w-[255px] h-[52px] rounded-[10px] pl-2.5 pr-5 text-[clamp(0.875rem,-0.9855rem+2.3256vw,1rem)] outline-none focus:outline-none"
                  activeClass="bg-base-yellow bg-opacity-20"
                  :class="[
                    $route.path === '/catalogo'
                      ? isCollapsed
                        ? 'text-base-yellow'
                        : 'text-black font-bold'
                      : 'text-base-gray-darker'
                  ]"
                >
                  <div
                    class="flex gap-x-2 w-[clamp(18.75rem,6.977vw+13.169rem,19.125rem)]"
                  >
                    <base-icon name="catalog" class="w-[22px] h-[22px]" />
                    <transition name="slide-fade">
                      <span v-show="!isCollapsed">Catálago</span>
                    </transition>
                  </div>
                  <base-icon
                    v-if="$route.path === '/catalogo'"
                    name="chevron-menu"
                    class="w-3 h-3"
                  />
                </router-link>
              </li>
              <li>
                <router-link
                  to="/pedidos"
                  class="flex justify-between items-center w-full max-w-[255px] h-[52px] rounded-[10px] pl-2.5 pr-5 text-[clamp(0.875rem,-0.9855rem+2.3256vw,1rem)] outline-none focus:outline-none"
                  activeClass="bg-base-yellow bg-opacity-20"
                  :class="[
                    $route.path === '/pedidos'
                      ? isCollapsed
                        ? 'text-base-yellow'
                        : 'text-black font-bold'
                      : 'text-base-gray-darker'
                  ]"
                >
                  <div
                    class="flex gap-x-2 w-[clamp(18.75rem,6.977vw+13.169rem,19.125rem)]"
                  >
                    <base-icon name="orders" class="w-[22px] h-[22px]" />
                    <transition name="slide-fade">
                      <span v-show="!isCollapsed">Pedidos</span>
                    </transition>
                  </div>
                  <base-icon
                    v-if="$route.path === '/pedidos'"
                    name="chevron-menu"
                    class="w-3 h-3"
                  />
                </router-link>
              </li>
              <li>
                <router-link
                  to="/reclamos"
                  class="flex justify-between items-center w-full max-w-[255px] h-[52px] rounded-[10px] pl-2.5 pr-5 text-[clamp(0.875rem,-0.9855rem+2.3256vw,1rem)] outline-none focus:outline-none"
                  activeClass="bg-base-yellow bg-opacity-20"
                  :class="[
                    $route.path === '/reclamos'
                      ? isCollapsed
                        ? 'text-base-yellow'
                        : 'text-black font-bold'
                      : 'text-base-gray-darker'
                  ]"
                >
                  <div
                    class="flex gap-x-2 w-[clamp(18.75rem,6.977vw+13.169rem,19.125rem)]"
                  >
                    <base-icon name="claims" class="w-[22px] h-[22px]" />
                    <transition name="slide-fade">
                      <span v-show="!isCollapsed">Reclamos</span>
                    </transition>
                  </div>
                  <base-icon
                    v-if="$route.path === '/reclamos'"
                    name="chevron-menu"
                    class="w-3 h-3"
                  />
                </router-link>
              </li>
            </ul>
          </div>

          <div>
            <ul role="list" class="flex flex-1 flex-col">
              <li>
                <router-link
                  to="/perfil"
                  class="flex justify-between items-center w-full max-w-[255px] h-[52px] rounded-[10px] pl-2.5 pr-5 text-base-gray-darker text-[clamp(0.875rem,-0.9855rem+2.3256vw,1rem)] outline-none focus:outline-none"
                  activeClass="bg-base-yellow bg-opacity-20"
                  :class="[
                    $route.path === '/perfil'
                      ? isCollapsed
                        ? 'text-base-yellow'
                        : 'text-black font-bold'
                      : 'text-base-gray-darker'
                  ]"
                >
                  <div
                    class="flex gap-x-2 w-[clamp(18.75rem,6.977vw+13.169rem,19.125rem)]"
                  >
                    <base-icon name="user" class="w-[22px] h-[22px]" />
                    <transition name="slide-fade">
                      <span class="min-w-max block" v-show="!isCollapsed">
                        Editar Perfil
                      </span>
                    </transition>
                  </div>
                  <base-icon
                    v-if="$route.path === '/perfil'"
                    name="chevron-menu"
                    class="w-3 h-3"
                  />
                </router-link>
              </li>
              <li>
                <router-link
                  to="/cuenta-corriente"
                  class="flex justify-between items-center w-full max-w-[255px] h-[52px] rounded-[10px] pl-2.5 pr-5 text-base-gray-darker text-[clamp(0.875rem,-0.9855rem+2.3256vw,1rem)] outline-none focus:outline-none"
                  activeClass="bg-base-yellow bg-opacity-20"
                  :class="[
                    $route.path === '/cuenta-corriente'
                      ? isCollapsed
                        ? 'text-base-yellow'
                        : 'text-black font-bold'
                      : 'text-base-gray-darker'
                  ]"
                >
                  <div
                    class="flex gap-x-2 w-[clamp(18.75rem,6.977vw+13.169rem,19.125rem)]"
                  >
                    <base-icon name="invoice" class="w-[22px] h-[22px]" />
                    <transition name="slide-fade">
                      <span class="min-w-max block" v-show="!isCollapsed">
                        Cuenta Corriente
                      </span>
                    </transition>
                  </div>
                  <base-icon
                    v-if="$route.path === '/cuenta-corriente'"
                    name="chevron-menu"
                    class="w-3 h-3"
                  />
                </router-link>
              </li>
              <li>
                <router-link
                  to="/descargas"
                  class="flex justify-between items-center w-full max-w-[255px] h-[52px] rounded-[10px] pl-2.5 pr-5 text-base-gray-darker text-[clamp(0.875rem,-0.9855rem+2.3256vw,1rem)] outline-none focus:outline-none"
                  activeClass="bg-base-yellow bg-opacity-20"
                  :class="[
                    $route.path === '/descargas'
                      ? isCollapsed
                        ? 'text-base-yellow'
                        : 'text-black font-bold'
                      : 'text-base-gray-darker'
                  ]"
                >
                  <div
                    class="flex gap-x-2 w-[clamp(18.75rem,6.977vw+13.169rem,19.125rem)]"
                  >
                    <base-icon name="downloads" class="w-[22px] h-[22px]" />
                    <transition name="slide-fade">
                      <span v-show="!isCollapsed">Descargas</span>
                    </transition>
                  </div>
                  <base-icon
                    v-if="$route.path === '/descargas'"
                    name="chevron-menu"
                    class="w-3 h-3"
                  />
                </router-link>
              </li>
              <li>
                <button
                  type="button"
                  @click="logout"
                  class="flex justify-between items-center w-full max-w-[255px] h-[52px] rounded-[10px] pl-2.5 pr-5 text-base-gray-darker text-[clamp(0.875rem,-0.9855rem+2.3256vw,1rem)] outline-none focus:outline-none"
                  activeClass="bg-base-yellow bg-opacity-20 text-black font-bold"
                >
                  <div
                    class="flex gap-x-2 w-[clamp(18.75rem,6.977vw+13.169rem,19.125rem)]"
                  >
                    <base-icon name="turn-off" class="w-[22px] h-[22px]" />
                    <transition name="slide-fade">
                      <span v-show="!isCollapsed">Salir</span>
                    </transition>
                  </div>
                </button>
              </li>
            </ul>
          </div>
        </nav>
        <div class="w-full border border-t-base-gray-light px-6 pb-4 relative">
          <Popover class="relative my-6">
            <PopoverButton
              class="flex gap-x-2 items-center w-full max-w-[255px] h-[52px] rounded-[10px] pl-2.5 pr-5 text-base-gray-darker text-[clamp(0.875rem,-0.9855rem+2.3256vw,1rem)] outline-none focus-visible:outline-none"
            >
              <div class="flex gap-2">
                <base-icon name="contact" class="w-[22px] h-[22px]" />
                <transition name="slide-fade">
                  <span v-show="!isCollapsed">Contacto</span>
                </transition>
              </div>
            </PopoverButton>

            <PopoverPanel
              class="absolute bottom-1 mb-2 left-48 z-50 bg-white border border-gray-200 drop-shadow-xl rounded-[10px] p-4 w-[182px]"
            >
              <div class="grid grid-cols-1 gap-7">
                <a
                  href="tel:+5401146571177"
                  class="flex items-center gap-4 text-base-gray-dark text-sm"
                >
                  <BaseIcon name="phone-v2" />
                  <span>Llamanos</span>
                </a>
                <a
                  class="flex items-center gap-4 text-base-gray-dark text-sm"
                  href="https://api.whatsapp.com/send?phone=5491127198352&text=Hola%20me%20comunico%20desde%20su%20pagina"
                >
                  <BaseIcon name="whatsapp-v2" />
                  <span>Whatsapp</span>
                </a>
                <a
                  class="flex items-center gap-4 text-base-gray-dark text-sm"
                  href="https://www.instagram.com/julmarsrl/"
                >
                  <BaseIcon name="instagram-v2" />
                  <span>Instagram</span>
                </a>
              </div>
            </PopoverPanel>
          </Popover>
          <div class="h-[15px]">
            <transition name="slide-fade">
              <span class="text-[10px] min-w-max block" v-show="!isCollapsed">
                Todos los derechos reservados
                <strong>Julmar &reg; 2025</strong>
              </span>
            </transition>
          </div>
        </div>
      </div>
    </div>

    <div
      class="min-h-screen bg-[#EBECEF]"
      :class="{ 'lg:pl-24': isCollapsed, 'lg:pl-72': !isCollapsed }"
    >
      <main class="p-3 2xl:p-5">
        <slot />
      </main>
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon.vue'
import { getActiveCart } from '@/api/cart'
import store from '@/store'
import { mapGetters } from 'vuex'
import currencyMixin from '@/mixins/currencyMixin'
import LoadingIndicator from '@/components/LoadingIndicator'
import { socketState } from '@/socket'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'

export default {
  name: 'SidebarLayout',
  components: {
    LoadingIndicator,
    BaseIcon,
    Popover,
    PopoverButton,
    PopoverPanel
  },
  mixins: [currencyMixin],
  data() {
    return {
      isCollapsed: false,
      showModal: false,
      showModalNovedades: false,
      modalClosed: false
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
    sidebarZIndex() {
      return this.showModal && this.showModalNovedades && this.modalClosed
        ? 'z-30'
        : 'z-0'
    },
    getCartNumbers() {
      const products = store.getters.getUserCart?.products || []
      let totalItems = 0
      let totalAmount = 0

      products.forEach((product) => {
        totalItems += parseInt(product.cantidad)

        const localAmount =
          product.precioBonificado * parseInt(product.cantidad)

        totalAmount += localAmount
      })

      return {
        totalItemsInCart: totalItems > 999 ? '+999' : totalItems,
        totalAmountInCart: totalAmount
      }
    },
    updatingCart() {
      return socketState.connected && socketState.updatingCart
    }
  },
  methods: {
    toggleSidebar() {
      this.isCollapsed = !this.isCollapsed
    },
    async logout() {
      this.$store.dispatch('afterSuccessLogin', null)
      this.$storage.removeStorageSync('loggedUser')
      this.$storage.removeStorageSync('showModal')
      this.$storage.removeStorageSync('showModalNovedades')
      this.$router.go()
    },
    async getUserCart() {
      const userId = store.getters.getUser?.id || null

      if (userId !== null) {
        const cartResponse = await getActiveCart(userId)

        if (cartResponse?.data?.data) {
          this.$store.dispatch('saveUserCart', cartResponse.data.data)
        }
      }
    },
    updateModalState() {
      this.showModal = !!this.$storage.getStorageSync('showModal')
      this.showModalNovedades =
        !!this.$storage.getStorageSync('showModalNovedades')
    },

    listenForModalChanges() {
      window.addEventListener('storage', this.updateModalState)
      document.addEventListener('updateModalState', this.updateModalState)
    },

    removeModalListeners() {
      window.removeEventListener('storage', this.updateModalState)
      document.removeEventListener('updateModalState', this.updateModalState)
    }
  },
  async created() {
    await this.getUserCart()

    this.emitter.on('update-modal-state', () => {
      this.updateModalState()
    })

    this.emitter.on('modal-opened', () => {
      this.modalClosed = false
    })
    this.emitter.on('modal-closed', () => {
      this.modalClosed = true
    })
  },
  mounted() {
    this.updateModalState()
    this.listenForModalChanges()
  },
  beforeUnmount() {
    this.removeModalListeners()
  },
  watch: {
    showModal() {
      this.updateModalState()
    },
    showModalNovedades() {
      this.updateModalState()
    }
  }
}
</script>

<style scoped>
.cart-active.router-link-active {
  background-color: rgba(75, 141, 114, 0.1);
  color: #4b8d72;
  border-bottom: 2px solid #4b8d72;
}
.slide-fade-enter-active {
  transition: transform 0.3s ease-out, opacity 0.3s;
}
.slide-fade-leave-active {
  transition: transform 0.2s ease-in, opacity 0.2s;
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}
</style>
