<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    v-bind="$attrs"
  >
    <path
      d="M17.6087 4.78261H15.5217L14.3696 3.04348C13.9348 2.3913 13.1739 2 12.3913 2H7.6087C6.82609 2 6.06522 2.3913 5.63043 3.04348L4.47826 4.78261H2.3913C1.06522 4.78261 0 5.84783 0 7.17391V15.6087C0 16.9348 1.06522 18 2.3913 18H17.6087C18.9348 18 20 16.9348 20 15.6087V7.17391C20 5.84783 18.9348 4.78261 17.6087 4.78261ZM10 16.0435C7.02174 16.0435 4.6087 13.6304 4.6087 10.6522C4.6087 7.67391 7.02174 5.28261 10 5.28261C12.9783 5.28261 15.3913 7.69565 15.3913 10.6739C15.3913 13.6304 12.9783 16.0435 10 16.0435ZM17.3043 8.15217C17.2826 8.15217 17.2609 8.15217 17.2174 8.15217H16.3478C15.9565 8.13043 15.6522 7.80435 15.6739 7.41304C15.6957 7.04348 15.9783 6.76087 16.3478 6.73913H17.2174C17.6087 6.71739 17.9348 7.02174 17.9565 7.41304C17.9783 7.80435 17.6957 8.13043 17.3043 8.15217Z"
      fill="currentColor"
    />
    <path
      d="M10 7.67391C8.34783 7.67391 7 9.02174 7 10.6739C7 12.3261 8.34783 13.6522 10 13.6522C11.6522 13.6522 13 12.3043 13 10.6522C13 9 11.6522 7.67391 10 7.67391Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconPhoto'
}
</script>
