import { get, put, post, destroy } from '@/config/api'
import store from '@/store'

// user id is client id
const getActiveCart = async (userId) => {
  try {
    return await get('/api/cartByUser/' + userId)
  } catch (e) {
    return e
  }
}

const updateCart = async (cartId, payload) => {
  const deviceId = store.getters.getUser?.deviceId || null
  const uniqueId = store.getters.getUuid || null

  if (deviceId) {
    payload.deviceId = deviceId
  }

  if (uniqueId) {
    payload.uniqueId = uniqueId
  }

  try {
    return await put('/api/cart/' + cartId, payload)
  } catch (e) {
    return e
  }
}

const createCart = async (payload) => {
  const deviceId = store.getters.getUser?.deviceId || null
  const uniqueId = store.getters.getUuid || null

  if (deviceId) {
    payload.deviceId = deviceId
  }

  if (uniqueId) {
    payload.uniqueId = uniqueId
  }

  try {
    return await post('/api/cart', payload)
  } catch (e) {
    return e
  }
}

const getCart = async (id) => {
  try {
    return await get(`/api/cart/${id}`)
  } catch (e) {
    return e
  }
}

const deleteCart = async (id) => {
  try {
    const deviceId = store.getters.getUser?.deviceId || null
    const uniqueId = store.getters.getUuid || null
    const payload = {}

    if (deviceId) {
      payload.deviceId = deviceId
    }

    if (uniqueId) {
      payload.uniqueId = uniqueId
    }

    return await destroy(`/api/cart/${id}`, { data: payload })
  } catch (e) {
    return e
  }
}

export { getActiveCart, updateCart, createCart, getCart, deleteCart }
