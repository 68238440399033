<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.9583 0H5.04167C2.26417 0 0 2.26417 0 5.04167V16.9583C0 19.7358 2.26417 22 5.04167 22H16.9583C19.7358 22 22 19.7358 22 16.9583V5.04167C22 2.26417 19.7358 0 16.9583 0ZM19.25 16.9583C19.25 18.2233 18.2233 19.25 16.9583 19.25H5.04167C3.77667 19.25 2.75 18.2233 2.75 16.9583V5.04167C2.75 3.77667 3.77667 2.75 5.04167 2.75H16.9583C18.2233 2.75 19.25 3.77667 19.25 5.04167V16.9583ZM10.0833 5.95833V8.70833C10.0833 9.46917 9.46917 10.0833 8.70833 10.0833H5.95833C5.1975 10.0833 4.58333 9.46917 4.58333 8.70833V5.95833C4.58333 5.1975 5.1975 4.58333 5.95833 4.58333H8.70833C9.46917 4.58333 10.0833 5.1975 10.0833 5.95833ZM17.4167 5.95833V8.70833C17.4167 9.46917 16.8025 10.0833 16.0417 10.0833H13.2917C12.5308 10.0833 11.9167 9.46917 11.9167 8.70833V5.95833C11.9167 5.1975 12.5308 4.58333 13.2917 4.58333H16.0417C16.8025 4.58333 17.4167 5.1975 17.4167 5.95833ZM10.0833 13.2917V16.0417C10.0833 16.8025 9.46917 17.4167 8.70833 17.4167H5.95833C5.1975 17.4167 4.58333 16.8025 4.58333 16.0417V13.2917C4.58333 12.5308 5.1975 11.9167 5.95833 11.9167H8.70833C9.46917 11.9167 10.0833 12.5308 10.0833 13.2917ZM17.4167 13.2917V16.0417C17.4167 16.8025 16.8025 17.4167 16.0417 17.4167H13.2917C12.5308 17.4167 11.9167 16.8025 11.9167 16.0417V13.2917C11.9167 12.5308 12.5308 11.9167 13.2917 11.9167H16.0417C16.8025 11.9167 17.4167 12.5308 17.4167 13.2917Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconCatalog'
}
</script>
