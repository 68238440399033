<template>
  <svg width="35" height="47" viewBox="0 0 35 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M30.4083 17.8384C30.2815 17.6796 30.1279 17.7108 30.0474 17.7432C29.9799 17.7706 29.8253 17.858 29.8467 18.077C29.8724 18.34 29.8868 18.6081 29.8896 18.8741C29.901 19.9773 29.4585 21.0581 28.6755 21.8394C27.8974 22.6156 26.874 23.0339 25.7844 23.0217C24.296 23.0027 23.0615 22.2264 22.2143 20.7767C21.5138 19.5779 21.8217 18.0318 22.1477 16.3947C22.3384 15.4366 22.5357 14.4457 22.5357 13.5027C22.5357 6.15994 17.5994 1.9237 14.657 0.0520493C14.5961 0.0134028 14.5382 -0.000183105 14.4869 -0.000183105C14.4034 -0.000183105 14.3373 0.0358013 14.3047 0.0578325C14.2416 0.10061 14.1405 0.198098 14.173 0.370676C15.2977 6.34316 11.9431 9.93527 8.39147 13.7382C4.73061 17.6582 0.581299 22.1013 0.581299 30.1144C0.581299 39.4252 8.1561 47 17.4669 47C25.133 47 31.8921 41.6552 33.9036 34.0025C35.2752 28.7845 33.8378 22.1376 30.4083 17.8384ZM17.8881 43.3958C15.5567 43.5021 13.3394 42.6659 11.6459 41.0466C9.97047 39.4446 9.00954 37.2089 9.00954 34.9127C9.00954 30.6036 10.6571 27.4403 15.0885 23.2408C15.161 23.172 15.2353 23.1503 15.3 23.1503C15.3587 23.1503 15.4095 23.1682 15.4445 23.185C15.5182 23.2205 15.6394 23.3084 15.623 23.4986C15.4646 25.3424 15.4674 26.8727 15.6311 28.0473C16.0497 31.0477 18.2461 33.0637 21.0969 33.0637C22.4946 33.0637 23.8259 32.5377 24.8457 31.5825C24.964 31.4716 25.0962 31.4858 25.1469 31.4965C25.214 31.511 25.3038 31.5521 25.3509 31.6657C25.7738 32.6866 25.9898 33.7702 25.9932 34.8864C26.0066 39.3776 22.3708 43.1949 17.8881 43.3958Z" fill="currentColor"/>
  </svg>
</template>
<script>
export default {
  name: 'IconFire'
}
</script>
