<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
    <path d="M24.24 18.1999L20.79 14.7499C19.56 13.5199 17.47 14.0099 16.97 15.6099C16.6 16.7199 15.37 17.3299 14.26 17.0899C11.8 16.4699 8.46997 13.2699 7.85997 10.6899C7.48997 9.57994 8.22997 8.34994 9.33997 7.97994C10.94 7.48994 11.43 5.39994 10.2 4.15994L6.74997 0.709941C5.76997 -0.150059 4.28997 -0.150059 3.42997 0.709941L1.08997 3.04994C-1.25003 5.50994 1.33997 12.0399 7.11997 17.8199C12.91 23.6099 19.43 26.3099 21.89 23.8499L24.23 21.5099C25.09 20.5299 25.09 19.0499 24.23 18.1899L24.24 18.1999Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'IconPhone'
}
</script>
