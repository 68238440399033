<template>
  <div
    class="flex w-full h-auto items-center justify-center inset-0 z-[999] bg-black bg-opacity-30"
    :class="{
      'fixed ': fixed,
      absolute: !fixed
    }"
  >
    <BaseIcon name="cog" class="h-8 w-8 text-base-yellow animate-spin" />
  </div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon.vue'

export default {
  name: 'LoadingIndicator',
  components: {
    BaseIcon
  },
  props: {
    fixed: {
      type: Boolean,
      default: false
    }
  }
}
</script>
