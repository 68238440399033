<template>
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-bind="$attrs"
  >
    <g clip-path="url(#clip0_140_3444)">
      <path
        d="M19.82 9.85996L17.89 7.12996C17.57 6.67996 16.95 6.56996 16.5 6.88996L13.77 8.81996C13.32 9.13996 13.21 9.75996 13.53 10.21C13.85 10.66 14.47 10.77 14.92 10.45L15.83 9.79996C15.63 10.46 15.33 11.1 14.92 11.67C13.76 13.26 11.96 14.17 10 14.17C8.22001 14.17 6.54001 13.4 5.39001 12.04C5.03001 11.62 4.40001 11.57 3.98001 11.92C3.56001 12.28 3.51001 12.91 3.86001 13.33C5.39001 15.13 7.62001 16.16 9.99001 16.16C12.56 16.16 15 14.92 16.52 12.84C17.07 12.09 17.47 11.25 17.73 10.37L18.17 10.99C18.37 11.27 18.67 11.41 18.99 11.41C19.19 11.41 19.39 11.35 19.57 11.23C20.02 10.91 20.13 10.29 19.81 9.83996L19.82 9.85996Z"
        fill="currentColor"
      />
      <path
        d="M6.48002 5.96001C6.16002 5.51001 5.54002 5.40001 5.09002 5.72001L4.18002 6.37001C4.38002 5.71001 4.68002 5.07001 5.09002 4.50001C6.25002 2.91001 8.05002 2.00001 10.01 2.00001C11.79 2.00001 13.47 2.77001 14.62 4.13001C14.98 4.55001 15.61 4.60001 16.03 4.25001C16.45 3.89001 16.5 3.26001 16.15 2.84001C14.62 1.04001 12.39 0.0100098 10.02 0.0100098C7.45003 0.0100098 5.01002 1.25001 3.49002 3.33001C2.94002 4.08001 2.54002 4.92001 2.28002 5.80001L1.84002 5.18001C1.52002 4.73001 0.900025 4.62001 0.450025 4.94001C2.45273e-05 5.26001 -0.109975 5.88001 0.210025 6.33001L2.14002 9.06001C2.34002 9.34001 2.64002 9.48001 2.96002 9.48001C3.16002 9.48001 3.36002 9.42001 3.54002 9.30001L6.27002 7.37001C6.72002 7.05001 6.83002 6.43001 6.51002 5.98001L6.48002 5.96001Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_140_3444">
        <rect width="20" height="16.18" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconRefresh'
}
</script>
