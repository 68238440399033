import { createStore } from 'vuex'
import { useStorage } from 'vue3-storage'
import { getOrders } from '@/api/orders'

const storage = useStorage()

export default createStore({
  state: {
    account: null,
    loading: false,
    isSubscriptionDetailsOpen: false,
    isSubscriptionReactivateOpen: false,
    userCart: null,
    isLoggedIn: false,
    orders: [],
    ocultarPrecioLista: JSON.parse(
      sessionStorage.getItem('ocultarPrecioLista') || 'false'
    ),
    ocultarPrecioBonificado: JSON.parse(
      sessionStorage.getItem('ocultarPrecioBonificado') || 'false'
    )
  },
  getters: {
    isLoggedIn(state) {
      return state.account !== null
    },
    getUuid() {
      let res = null

      if (storage.hasKey('userUuid')) {
        res = storage.getStorageSync('userUuid')
      } else {
        res = Math.random().toString(16).slice(2);
        storage.setStorageSync('userUuid', res)
      }

      return res
    },
    getUser(state) {
      if (state.account !== null) {
        return state.account
      } else if (storage.hasKey('loggedUser')) {
        state.account = storage.getStorageSync('loggedUser')
        return state.account
      } else {
        return null
      }
    },
    getUserCart(state) {
      return state.userCart
    },
    getSubscriptionDetails(state) {
      return state.isSubscriptionDetailsOpen
    },
    getOrders(state) {
      return state.orders
    }
  },
  mutations: {
    setloggedUser(state, loggedUser) {
      state.account = loggedUser
      state.isLoggedIn = true
    },
    setUserCart(state, userCart) {
      state.userCart = userCart
    },
    setLoading(state, loading) {
      state.loading = loading
    },
    setOrders(state, orders) {
      state.orders = orders
    },
    setOcultarPrecioLista(state, value) {
      state.ocultarPrecioLista = value
      localStorage.setItem('ocultarPrecioLista', JSON.stringify(value))
    },
    setOcultarPrecioBonificado(state, value) {
      state.ocultarPrecioBonificado = value
      localStorage.setItem('ocultarPrecioBonificado', JSON.stringify(value))
    }
  },
  actions: {
    afterSuccessLogin({ commit }, loggedUser) {
      commit('setloggedUser', loggedUser)
    },
    saveUserCart({ commit }, userCart) {
      commit('setUserCart', userCart)
    },
    setLoading({ commit }, loading) {
      commit('setLoading', loading)
    },
    async fetchOrders({ commit }) {
      commit('setLoading', true)
      try {
        const response = await getOrders()
        const data = response.data.data || []
        const pages = Math.ceil(data.length / 10)
        const orders = Array.from({ length: pages }, (_, i) =>
          data.slice(i * 10, i * 10 + 10)
        )
        commit('setOrders', orders)
      } catch (error) {
        console.error('Error fetching orders:', error)
      } finally {
        commit('setLoading', false)
      }
    },
    updateOcultarPrecioLista({ commit }, value) {
      commit('setOcultarPrecioLista', value)
    },
    updateOcultarPrecioBonificado({ commit }, value) {
      commit('setOcultarPrecioBonificado', value)
    },
    updateFiltros({ commit }, value) {
      commit('setFiltros', value)
    }
  },
  modules: {}
})
