<template>
  <svg
    width="18"
    height="23"
    viewBox="0 0 18 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.3498 10.0625V13.8957C10.3498 14.6904 9.74608 15.3332 8.99979 15.3332C8.2535 15.3332 7.6498 14.6904 7.6498 13.8957V10.0625C7.6498 9.26783 8.2535 8.625 8.99979 8.625C9.74608 8.625 10.3498 9.26783 10.3498 10.0625ZM8.99979 16.2918C8.25476 16.2918 7.6498 16.936 7.6498 17.7293C7.6498 18.5226 8.25476 19.1668 8.99979 19.1668C9.74481 19.1668 10.3498 18.5226 10.3498 17.7293C10.3498 16.936 9.74481 16.2918 8.99979 16.2918ZM18 7.81731V17.7293C18 20.6358 15.7797 23 13.0502 23H4.94982C2.22031 23 0 20.6358 0 17.7293V5.27068C0 2.36424 2.22031 0 4.94982 0H10.6586C11.9807 0 13.2236 0.548047 14.1589 1.54396L16.55 4.09014C17.4853 5.08605 18 6.40856 18 7.81731ZM15.3 17.7293V8.625H11.7002C10.7058 8.625 9.90006 7.76744 9.90006 6.70818V2.875H4.95024C3.70994 2.875 2.7004 3.95043 2.7004 5.27068V17.7289C2.7004 19.0496 3.71036 20.1245 4.95024 20.1245H13.0502C14.2905 20.1245 15.3 19.0491 15.3 17.7289V17.7293Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconClaims'
}
</script>
