import { get } from '@/config/api'

const getOrders = async () => {
  try {
    return await get('/api/carts')
  } catch (e) {
    return e
  }
}

export {
  getOrders
}