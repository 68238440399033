<template>
  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind="$attrs">
    <path d="M22.5 9.09375C22.5 8.81882 22.3969 8.55764 22.2046 8.36514C22.0052 8.17264 21.7437 8.0625 21.4688 8.0625L14.9375 8.06958V1.53125C14.9375 1.26319 14.8344 0.995137 14.6421 0.802637C14.4427 0.610137 14.1812 0.5 13.9062 0.5L9.10049 0.507081C8.53014 0.507081 8.06924 0.967637 8.06924 1.53833V8.0625H1.53125C1.26306 8.0625 1.00188 8.17264 0.802431 8.36514C0.610138 8.55764 0.5 8.81882 0.5 9.09375V13.9133C0.5 14.4838 0.967637 14.9446 1.53125 14.9446H8.06924L8.0625 21.4688C8.0625 21.7438 8.17264 22.0052 8.36493 22.1976C8.55764 22.3969 8.81882 22.5 9.09375 22.5H13.9062C14.4769 22.5 14.9375 22.0394 14.9375 21.4688V14.9375H21.4688C22.0394 14.9375 22.5 14.4769 22.5 13.9062V9.09375Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconPlus'
}
</script>