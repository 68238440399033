<template>
  <div class="flex flex-col min-h-screen bg-white w-full">
    <LoadingIndicator v-if="updatingCart" />
    <header
      class="flex items-center justify-center relative h-20 lg:h-[63px] bg-white max-w-max mx-auto gap-2 lg:gap-4"
    >
      <div class="flex flex-1 flex-shrink items-center justify-start w-auto">
        <router-link
          to="/"
          class="flex items-center text-base-gray-dark text-sm font-normal leading-normal outline-none focus-visible:outline-none max-w-max h-[63px] mr-4 lg:mr-[50px]"
        >
          <BaseIcon name="julmar" class="text-base-gray-dark" />
        </router-link>
        <router-link
          to="/catalogo"
          class="flex items-center text-base-gray-dark text-sm font-normal leading-normal outline-none focus-visible:outline-none max-w-max h-[63px] px-4"
        >
          Catálogo
        </router-link>
        <router-link
          to="/pedidos"
          class="flex items-center text-base-gray-dark text-sm font-normal leading-normal outline-none focus-visible:outline-none max-w-max h-[63px] px-4"
        >
          Pedidos
        </router-link>
        <router-link
          to="/reclamos"
          class="flex items-center text-base-gray-dark text-sm font-normal leading-normal outline-none focus-visible:outline-none max-w-max h-[63px] px-4"
        >
          Reclamos
        </router-link>
        <router-link
          to="/carrito"
          class="flex items-center outline-none focus-visible:outline-none max-w-max h-[63px] px-4 cart-active gap-1"
        >
          <div class="flex items-center justify-center relative w-10 h-9">
            <BaseIcon name="cart" class="text-base-green" />
            <div
              class="absolute flex items-center justify-center text-white font-bold leading-normal"
            >
              <span class="mt-[-10px] ml-1.5 text-xs">
                {{ getCartNumbers.totalItemsInCart }}
              </span>
            </div>
          </div>
          <span class="text-base-green text-2xl font-bold leading-normal">
            {{ formatCurrency(getCartNumbers.totalAmountInCart) }}
          </span>
        </router-link>
      </div>
      <div class="flex flex-1 flex-grow max-w-max" v-if="isLoggedIn">
        <MenuVue as="div" class="relative inline-block text-left">
          <div>
            <MenuButton
              class="inline-flex w-full justify-center items-center gap-x-1.5 rounded-[5px] bg-white px-3 py-2 text-base-gray-dark text-sm font-normal leading-normal h-[39px] shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 outline-none focus-visible:outline-none"
            >
              <span class="hidden lg:block">Configuración</span>
              <BaseIcon
                name="cog-solid"
                class="block lg:hidden h-5 w-5 text-base-gray-dark"
              />
              <BaseIcon
                name="chevron-down"
                class="-mr-1 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </MenuButton>
          </div>
          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                  <a
                    href="/perfil"
                    :class="[
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'w-full text-left block px-4 py-2 text-sm'
                    ]"
                  >
                    Editar Perfil
                  </a>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a
                    href="/cuenta-corriente"
                    :class="[
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'w-full text-left block px-4 py-2 text-sm'
                    ]"
                  >
                    Cuenta Corriente
                  </a>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a
                    href="/descargas"
                    :class="[
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'w-full text-left block px-4 py-2 text-sm'
                    ]"
                  >
                    Descargas
                  </a>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <button
                    type="button"
                    @click="logout"
                    :class="[
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'w-full text-left block px-4 py-2 text-sm'
                    ]"
                  >
                    Salir
                  </button>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </MenuVue>
      </div>
    </header>
    <main class="flex-1 antialiased">
      <slot />
    </main>
    <footer class="flex flex-col">
      <div
        class="flex flex-col bg-base-gray-mediumLight w-full h-auto items-center justify-center pt-[29px] pb-[56px]"
      >
        <h3 class="text-[#666] text-2xl font-bold leading-normal mb-[34px]">
          Contacto Directo
        </h3>
        <div class="flex flex-col lg:flex-row gap-[18px]">
          <div class="flex gap-[18px]">
            <a
              href="tel:+5401146571177"
              class="bg-[#333333] flex items-center justify-center gap-3 p-2 rounded-full w-[196px] h-[45px] text-white text-sm font-normal leading-normal uppercase"
            >
              <BaseIcon name="phone" />
              <span>Llamanos</span>
            </a>
            <a
              class="bg-[#333333] flex items-center justify-center gap-3 p-2 rounded-full w-[196px] h-[45px] text-white text-sm font-normal leading-normal uppercase"
              href="https://api.whatsapp.com/send?phone=5491127198352&text=Hola%20me%20comunico%20desde%20su%20pagina"
            >
              <BaseIcon name="whatsapp" />
              <span>Whatsapp</span>
            </a>
          </div>
          <div class="flex gap-[18px]">
            <a
              class="bg-[#333333] flex items-center justify-center gap-3 p-2 rounded-full w-[196px] h-[45px] text-white text-sm font-normal leading-normal uppercase"
              href="https://goo.gl/maps/6n27aokppeF2"
            >
              <BaseIcon name="marker" />
              <span>Ubicación</span>
            </a>
            <a
              class="bg-[#333333] flex items-center justify-center gap-3 p-2 rounded-full w-[196px] h-[45px] text-white text-sm font-normal leading-normal uppercase"
              href="https://www.instagram.com/julmarsrl/"
            >
              <BaseIcon name="instagram" />
              <span>Instagram</span>
            </a>
          </div>
        </div>
      </div>
      <div
        class="flex flex-col items-center justify-center w-full h-[204px] bg-base-gray p-6"
      >
        <h3
          class="text-base-gray-mediumLight text-2xl font-bold leading-normal mb-6"
        >
          Mapa del Sitio
        </h3>
        <div class="flex space-x-[100px]">
          <div>
            <h4 class="text-[#B1B1B1] text-base font-bold leading-normal">
              Institucional
            </h4>
            <div class="flex mt-4">
              <div class="flex gap-2">
                <div class="flex flex-col w-[104px]">
                  <ul
                    class="list-none text-[#B1B1B1] text-xs font-normal leading-normal no-underline"
                  >
                    <li>Home</li>
                    <li>Marcas</li>
                    <li>Contacto</li>
                  </ul>
                </div>
                <div class="flex flex-col w-[104px]">
                  <ul
                    class="list-none text-[#B1B1B1] text-xs font-normal leading-normal no-underline"
                  >
                    <li>App móvil IOs</li>
                    <li>App móvil Android</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h4 class="text-[#B1B1B1] text-base font-bold leading-normal">
              Julmar App
            </h4>
            <div class="flex mt-4">
              <div class="flex gap-2">
                <div class="flex flex-col w-[104px]">
                  <ul
                    class="list-none text-[#B1B1B1] text-xs font-normal leading-normal no-underline"
                  >
                    <li>Catálogo</li>
                    <li>Pedidos</li>
                    <li>Reclamos</li>
                  </ul>
                </div>
                <div class="flex flex-col w-[104px]">
                  <ul
                    class="list-none text-[#B1B1B1] text-xs font-normal leading-normal no-underline"
                  >
                    <li>Perfil</li>
                    <li>Conf. Márgenes</li>
                    <li>Editar Perfil</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="relative flex flex-col gap-4 w-full items-center justify-center h-[80px] lg:h-[74px] bg-[#333333]"
      >
        <BaseIcon name="julmar" class="text-base-gray" />
        <span
          class="lg:absolute lg:top-7 lg:right-[144px] ring-0 text-sm text-[#B1B1B1] font-normal leading-normal"
          >Todos los derechos reservados Julmar® 2023</span
        >
      </div>
    </footer>
  </div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon.vue'
import { getActiveCart } from '@/api/cart'
import store from '@/store'
import {
  Menu as MenuVue,
  MenuButton,
  MenuItem,
  MenuItems
} from '@headlessui/vue'
import { mapGetters } from 'vuex'
import currencyMixin from '@/mixins/currencyMixin'
import LoadingIndicator from '@/components/LoadingIndicator'
import { socketState } from '@/socket'

export default {
  name: 'DashboardLayout',
  components: {
    LoadingIndicator,
    BaseIcon,
    MenuVue,
    MenuButton,
    MenuItem,
    MenuItems
  },
  mixins: [currencyMixin],
  computed: {
    ...mapGetters(['isLoggedIn']),
    getCartNumbers() {
      const products = store.getters.getUserCart?.products || []
      let totalItems = 0
      let totalAmount = 0

      products.forEach((product) => {
        totalItems += parseInt(product.cantidad)

        const localAmount =
          product.precioBonificado * parseInt(product.cantidad)

        totalAmount += localAmount
      })

      return {
        totalItemsInCart: totalItems > 999 ? '+999' : totalItems,
        totalAmountInCart: totalAmount
      }
    },
    updatingCart() {
      return socketState.connected && socketState.updatingCart
    }
  },
  methods: {
    async logout() {
      this.$store.dispatch('afterSuccessLogin', null)
      this.$storage.removeStorageSync('loggedUser')
      this.$storage.removeStorageSync('showModal')
      this.$router.go()
    },
    async getUserCart() {
      const userId = store.getters.getUser?.id || null

      if (userId !== null) {
        const cartResponse = await getActiveCart(userId)

        if (cartResponse?.data?.data) {
          this.$store.dispatch('saveUserCart', cartResponse.data.data)
        }
      }
    }
  },
  async created() {
    await this.getUserCart()
  }
}
</script>

<style scoped>
.router-link-active {
  background-color: rgba(242, 168, 64, 0.1);
  color: #000;
  border-bottom: 2px solid #f2a840;
}
.cart-active.router-link-active {
  background-color: rgba(75, 141, 114, 0.1);
  color: #4b8d72;
  border-bottom: 2px solid #4b8d72;
}
</style>
