<template>
  <svg
    width="17"
    height="22"
    viewBox="0 0 17 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5395 6.41553H5.95681C5.19767 6.41553 4.58203 7.03117 4.58203 7.7903C4.58203 8.54944 5.19767 9.16508 5.95681 9.16508H10.5395C11.2987 9.16508 11.9143 8.54944 11.9143 7.7903C11.9143 7.03117 11.2987 6.41553 10.5395 6.41553Z"
      fill="currentColor"
    />
    <path
      d="M8.70733 10.998H5.95778C5.19865 10.998 4.58301 11.6137 4.58301 12.3728C4.58301 13.132 5.19865 13.7476 5.95778 13.7476H8.70733C9.46647 13.7476 10.0821 13.132 10.0821 12.3728C10.0821 11.6137 9.46647 10.998 8.70733 10.998Z"
      fill="currentColor"
    />
    <path
      d="M11.4566 0H5.0407C2.25807 0.00300732 0.00300732 2.25807 0 5.0407V20.6216C0 21.3808 0.615212 21.9964 1.37435 21.9968C1.58787 21.9968 1.79881 21.947 1.98956 21.8516L4.99774 20.3467L7.56385 21.813C7.98659 22.0544 8.50514 22.0544 8.92745 21.813L11.4936 20.3467L14.5017 21.8516C15.1792 22.1945 16.0063 21.9229 16.3491 21.2454C16.447 21.0521 16.4977 20.8386 16.4969 20.6216V5.0407C16.4943 2.25807 14.2392 0.00300732 11.4566 0ZM13.7478 18.3971L12.0705 17.5585C11.6594 17.3527 11.1726 17.3664 10.7735 17.5941L8.24866 19.0376L5.72379 17.5967C5.32468 17.3686 4.83792 17.3553 4.42678 17.561L2.74955 18.3971V5.0407C2.74955 3.77505 3.77548 2.74955 5.0407 2.74955H11.4562C12.7218 2.74955 13.7473 3.77548 13.7473 5.0407V18.3971H13.7478Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconInvoice'
}
</script>
