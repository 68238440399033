<template>
  <svg
    width="7"
    height="11"
    viewBox="0 0 7 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.27031 5.50003C6.27031 5.69717 6.19504 5.89429 6.04481 6.04459L1.31501 10.7743C1.01413 11.0752 0.526316 11.0752 0.225564 10.7743C-0.0751881 10.4736 -0.0751881 9.98587 0.225564 9.68498L4.41075 5.50003L0.22571 1.31506C-0.0750423 1.01419 -0.0750423 0.526516 0.22571 0.225788C0.526462 -0.0752323 1.01428 -0.0752323 1.31515 0.225788L6.04495 4.95547C6.19521 5.10584 6.27031 5.30296 6.27031 5.50003Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconChevronMenu'
}
</script>
