<template>
  <div v-if="isMobile" class="flex flex-col bg-base-gray-light min-h-screen">
    <div class="flex justify-center items-center bg-white w-full h-[72px]">
      <base-icon
        name="julmar"
        class="text-base-gray-dark max-w-[98px] h-auto"
      />
    </div>
    <div
      class="flex flex-col flex-1 flex-grow-0 flex-shrink bg-base-gray-lighter justify-center items-center pt-8 pb-14 px-7"
    >
      <div
        class="flex text-base-yellow font-bold text-2xl leading-6 mb-4 w-full text-center font-montserrat"
      >
        App Dispositivos Móviles
      </div>
      <div
        class="flex text-center w-full font-montserrat text-base-gray-darker mb-7"
      >
        Descarga e instala la aplicación Nativa para dispositivos móviles desde
        los siguientes enlaces
      </div>
      <div class="flex w-full justify-between items-center mb-10">
        <img
          src="/images/android.svg"
          alt="Play Store"
          class="w-[60px] h-auto"
        />
        <a
          href="https://apijulmar.com.ar/descargas/app-julmar-6-5-0.apk"
          target="_blank"
          rel="noopener noreferrer"
          class="font-montserrat bg-base-yellow text-white text-base font-bold rounded-3xl w-[217px] h-[38px] flex items-center justify-center cursor-pointer visited:text-white visited:no-underline no-underline"
        >
          Versión Android
        </a>
      </div>
      <div class="flex w-full justify-between items-center mb-10">
        <img
          src="/images/apple.svg"
          alt="Play Store"
          class="w-11 h-auto mb-2 ml-2"
        />
        <a
          href="#"
          target="_blank"
          rel="noopener noreferrer"
          class="font-montserrat bg-base-gray-light text-white text-base font-bold rounded-3xl w-[217px] h-[38px] flex items-center justify-center cursor-pointer visited:text-white visited:no-underline no-underline"
        >
          Versión Android
        </a>
      </div>
      <div
        class="flex w-full text-center text-base leading-6 font-montserrat text-base-gray-darker px-4"
      >
        Una vez instalada la App, podrá habilitar el acceso en su dispositivo
      </div>
      <div
        class="flex w-full text-center text-base leading-6 font-montserrat text-base-gray-darker font-bold mt-1"
      >
        Recuerde que sólo podrá acceder desde un dispositivo móvil autorizado
      </div>
    </div>
  </div>
  <div v-else class="w-full min-h-screen bg-white flex">
    <div class="w-2/3 h-screen">
      <img
        src="/images/login.jpg"
        alt="Julmar"
        class="w-full h-full object-cover"
      />
    </div>
    <div class="w-1/3 p-4 xl:p-20 flex flex-col">
      <div class="flex flex-col flex-1 items-center justify-center">
        <form
          @submit.prevent="login"
          class="flex flex-col w-full xl:w-[388px] gap-[32px]"
        >
          <div class="flex items-center justify-center">
            <img src="/images/julmar.svg" alt="Julmar" class="w-[192px] h-11" />
          </div>
          <div class="flex flex-col gap-6">
            <base-input
              id="email"
              label="Email"
              type="email"
              v-model="email"
              placeholder="name@example.com"
              required
              @keydown.enter.prevent="login"
              @blur="onBlur"
              :error="this.v$.email.$errors[0]?.$message"
            />
            <base-input
              id="password"
              label="Password"
              type="password"
              v-model="password"
              placeholder="password"
              required
              @keydown.enter.prevent="login"
              @blur="onBlur"
              :error="this.v$.password.$errors[0]?.$message"
            />
            <button
              type="submit"
              class="flex items-center justify-center bg-base-yellow rounded-[5px] w-full xl:w-[388px] h-[45px] font-bold text-lg text-white"
            >
              Ingresar
            </button>
          </div>
          <div
            v-if="!attemptedLogin"
            class="flex flex-col mt-[35px] pl-4 w-full"
          >
            <span class="text-base-gray-darker text-base font-light">
              Si no tenés u olvidaste tu clave y/o usuario
            </span>
            <router-link
              to="/registro"
              class="text-base-yellow text-base font-bold underline visited:no-underline"
            >
              Crear o recuperar clave y/o usuario
            </router-link>
          </div>
          <div
            v-else-if="badRequest"
            class="flex flex-col bg-base-gray-soft rounded-[5px] w-[388px] h-auto mt-[22px] px-5 py-4"
          >
            <div class="flex gap-2 items-center">
              <base-icon name="minus-circle" class="w-7 h-7 text-[#F44336]" />
              <span class="text-base text-[#F44336] font-bold">
                Error de credenciales
              </span>
            </div>
            <div class="pb-2 pt-4">Usuario y/o password no coinciden</div>
          </div>
          <div
            v-else-if="wrongCredentials && !authorizationRequestSent"
            class="flex flex-col bg-base-gray-soft rounded-[5px] w-[388px] h-auto mt-[22px] px-5 py-4"
          >
            <div class="flex gap-2 items-center">
              <base-icon name="minus-circle" class="w-7 h-7 text-[#F44336]" />
              <span class="text-base text-[#F44336] font-bold">
                Dispositivo no autorizado
              </span>
            </div>
            <div class="pb-2 pt-4">
              Solicite habilitación de este dispositivo/navegador para poder
              acceder. Recuerde que sólo podrá acceder desde un dispositivo
              autorizado. Nro identificador de
              <strong>Dispositivo/Navegador: {{ deviceId }}</strong>
            </div>
            <div>
              <button
                type="button"
                @click.prevent="requestAuthorization"
                class="flex items-center justify-center bg-base-yellow rounded-[5px] w-full h-[45px] font-bold text-lg text-white"
              >
                Enviar Solicitud
              </button>
            </div>
          </div>
          <div
            v-else-if="attemptedLogin && authorizationRequestSent"
            class="flex flex-col bg-base-gray-soft rounded-[5px] w-[388px] h-auto mt-[22px] px-5 py-4"
          >
            <div class="flex gap-2 items-center">
              <base-icon name="check-circle" class="w-7 h-7 text-base-gray" />
              <span class="text-base text-base-gray font-bold">
                Solicitud Enviada
              </span>
            </div>
            <div class="pb-2 pt-4">
              <span class="text-base text-base-gray-darker">
                Para agilizar el proceso comuníquese a través de cualquiera de
                nuestros canales (Whatsapp - Teléfono)
              </span>
            </div>
          </div>
        </form>
      </div>
      <div class="flex flex-col flex-0 gap-5">
        <span class="text-black text-base">
          Descargar la App para Celulares
        </span>
        <div class="flex gap-4">
          <img
            src="/images/app-store.svg"
            alt="App Store"
            class="w-[150px] h-auto xl:w-[164px] xl:h-[89px]"
          />
          <img
            src="/images/google-play.svg"
            alt="Google Play"
            class="w-[150px] h-auto xl:w-[164px] xl:h-[89px]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/Base/BaseInput.vue'
import BaseIcon from '@/components/Base/BaseIcon.vue'
import useVuelidate from '@vuelidate/core'
import { email, required, helpers } from '@vuelidate/validators'
import { login, requestAuthorization } from '@/api/auth'
import smoothScrollMixin from '@/mixins/scrollToUpMixin'

export default {
  name: 'LoginView',
  components: { BaseIcon, BaseInput },
  mixins: [smoothScrollMixin],
  data() {
    const remember = localStorage.getItem('remember') === 'true'

    return {
      loading: false,
      isDisabled: false,
      v$: useVuelidate(),
      email: remember ? localStorage.getItem('email') : '',
      password: '',
      remember,
      wrongCredentials: false,
      authorizationRequestSent: false,
      attemptedLogin: false,
      deviceId: null,
      clientId: null,
      badRequest: false,
      isMobile: false
    }
  },
  methods: {
    onBlur(e) {
      this.v$[e.target.id].$touch()
    },
    async login() {
      this.attemptedLogin = true
      this.badRequest = false

      if (this.isDisabled !== true && this.loading !== true) {
        this.isDisabled = true
        this.loading = true
        this.wrongCredentials = false

        const loginResponse = await login(this.email, this.password)

        if (loginResponse.status === 200) {
          const userInfo = loginResponse.data.data

          this.$store.dispatch('afterSuccessLogin', userInfo)
          this.$storage.setStorageSync('loggedUser', userInfo)
          this.$router.go()
        } else if (loginResponse.status === 400) {
          this.badRequest = true
          this.isDisabled = false
          this.loading = false
        } else {
          this.isDisabled = false
          this.loading = false
          this.wrongCredentials = true
          this.authorizationRequestSent = false

          if (loginResponse?.data?.deviceId) {
            this.deviceId = loginResponse.data.deviceId
            this.clientId = loginResponse.data.clientId
          }
        }
      }
    },
    async requestAuthorization() {
      this.authorizationRequestSent = true

      if (this.deviceId && this.clientId) {
        await requestAuthorization(this.deviceId, this.clientId)
      }
    },
    checkIfMobile() {
      const isSmallScreen = window.innerWidth < 480
      const isUserAgentMobile =
        /Mobi|Android|iPhone|iPod|BlackBerry|Windows Phone/i.test(
          navigator.userAgent
        )
      this.isMobile =
        isSmallScreen || (isUserAgentMobile && !this.isDebugging())
    },
    isDebugging() {
      return (
        window.navigator.userAgent.includes('Chrome') && window.innerWidth > 480
      )
    }
  },
  validations: {
    email: {
      email: helpers.withMessage('Por favor ingrese un email válido.', email),
      required: helpers.withMessage('El email es requerido.', required)
    },
    password: {
      required: helpers.withMessage('El password es requerido.', required)
    }
  },
  beforeCreate() {
    if (this.$store.getters.getUser !== null) {
      this.$router.push('/')
    }
  },

  mounted() {
    this.checkIfMobile()
    window.addEventListener('resize', this.checkIfMobile)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkIfMobile)
  }
}
</script>
