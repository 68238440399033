<template>
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.4855 2.19451L7.84081 2.56831L8.20343 2.20159C9.77915 0.608069 12.3607 0.590438 13.9539 2.17311C15.5194 3.7581 15.5192 6.31272 13.9533 7.89749C13.953 7.89782 13.9527 7.89815 13.9524 7.89848L7.87435 13.9765C7.85961 13.9912 7.83619 13.9912 7.82145 13.9765L1.74145 7.89648C1.72329 7.87831 1.70622 7.86215 1.69238 7.84918L1.65803 7.81706C1.64252 7.80237 1.63799 7.79722 1.63786 7.79709L1.625 7.78102L1.6103 7.76555C0.0715245 6.14672 0.141502 3.58315 1.76238 2.04243C3.38121 0.503653 5.94477 0.573631 7.4855 2.19451Z"
      stroke="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconHeart'
}
</script>
