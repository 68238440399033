<template>
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.6069 11.5004L22.5629 2.54413C23.146 1.96177 23.146 1.01937 22.5629 0.437013C21.9806 -0.145345 21.0382 -0.145345 20.4559 0.437013L11.4999 9.3933L2.54384 0.437013C1.9615 -0.145345 1.01983 -0.145345 0.436791 0.437013C-0.146248 1.01937 -0.146248 1.96177 0.436791 2.54413L9.39281 11.5004L0.436791 20.4567C-0.146248 21.0391 -0.146248 21.9815 0.436791 22.5638C0.726913 22.8547 1.10862 23.0001 1.49032 23.0001C1.87202 23.0001 2.25302 22.854 2.54384 22.5638L11.4999 13.6075L20.4559 22.5638C20.7467 22.8547 21.1277 23.0001 21.5094 23.0001C21.8911 23.0001 22.2721 22.854 22.5629 22.5638C23.146 21.9815 23.146 21.0391 22.5629 20.4567L13.6069 11.5004Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconCloseModal'
}
</script>
