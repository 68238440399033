import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './index.css'
import DashboardLayout from './layouts/DashboardLayout.vue'
import LoginLayout from './layouts/LoginLayout.vue'
import SidebarLayout from './layouts/SidebarLayout.vue'
import Vue3Storage, { StorageType } from 'vue3-storage'
import Toaster from '@meforma/vue-toaster'
import mitt from 'mitt'

const emitter = mitt()
const app = createApp(App)
app.component('dashboard-layout', DashboardLayout)
app.component('login-layout', LoginLayout)
app.component('sidebar-layout', SidebarLayout)
app.use(store)
app.use(router)
app.use(Toaster, {
  position: 'top-right',
  queue: true,
  maxToasts: 1
})
app.use(Vue3Storage, { namespace: 'pro_', storage: StorageType.Local })
app.mount('#app')
app.config.globalProperties.emitter = emitter

app.config.errorHandler = (err, vm, info) => {
  console.error(err, vm, info)
}
