<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.0341 21.9975C8.55462 22.2076 -4.68555 8.97044 1.68091 1.77613C1.70372 1.75422 2.68334 0.901813 2.68334 0.901813C3.95895 -0.275828 5.93197 -0.259502 7.18779 0.939191L9.16295 3.06376C10.3727 4.32647 10.3568 6.32085 9.12724 7.56423L8.77102 7.92556C9.83152 10.271 11.7082 12.1528 14.052 13.2213L14.4293 12.8548C15.7002 11.6462 17.6973 11.6462 18.9678 12.8548L21.0208 14.7568C22.2865 15.9916 22.3201 18.0143 21.0961 19.2899C21.0961 19.2899 20.2425 20.2669 20.2206 20.2897C19.1098 21.3952 17.6018 22.01 16.0336 21.9971L16.0341 21.9975ZM3.59757 3.75805C-0.862145 9.27376 13.4127 22.706 18.2411 18.3774C18.2411 18.3774 19.0891 17.4068 19.1123 17.3849C19.2913 17.2058 19.2913 16.9158 19.1123 16.737L17.0584 14.8359C16.7831 14.6069 16.6278 14.6039 16.3727 14.7994L15.3302 15.824C14.946 16.2013 14.3768 16.3203 13.8735 16.1282C10.168 14.7512 7.24372 11.8353 5.85926 8.13651C5.65576 7.62953 5.77192 7.05038 6.15568 6.6607L7.17273 5.6287C7.35644 5.46587 7.37322 5.18489 7.20973 5.001C7.2007 4.99069 7.1908 4.98081 7.18091 4.97136L5.20402 2.84679C5.02247 2.69513 4.75401 2.71145 4.59181 2.88331C4.56987 2.90608 3.598 3.75762 3.598 3.75762L3.59757 3.75805ZM21.9996 8.7075C21.994 3.90069 18.0936 0.00558531 13.2806 0C12.5204 0 11.9039 0.615673 11.9039 1.37485C11.9039 2.13402 12.5204 2.74969 13.2806 2.74969C16.5736 2.75356 19.2423 5.41861 19.2466 8.7075C19.2466 9.46667 19.8631 10.0823 20.6233 10.0823C21.3835 10.0823 22 9.46667 22 8.7075H21.9996ZM17.4108 8.7075C17.4082 6.43084 15.5604 4.58554 13.2806 4.58296C12.5204 4.58296 11.9039 5.19864 11.9039 5.95781C11.9039 6.71698 12.5204 7.33265 13.2806 7.33265C14.0408 7.33265 14.6574 7.94833 14.6574 8.7075C14.6574 9.46667 15.2739 10.0823 16.0341 10.0823C16.7943 10.0823 17.4108 9.46667 17.4108 8.7075Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconContact'
}
</script>
