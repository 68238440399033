<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.04592 12.3352C8.45092 12.7518 8.44734 13.4233 8.03744 13.835L5.39871 16.4866C5.06185 16.828 4.61902 17 4.17488 17C3.73075 17 3.28302 16.8283 2.94356 16.4833L0.309721 13.8357C-0.100176 13.424 -0.103437 12.7525 0.301243 12.3359C0.706249 11.9186 1.36756 11.9159 1.77746 12.3272L3.13041 13.6868V1.06064C3.13041 0.475297 3.59738 0 4.17391 0C4.75044 0 5.2174 0.475297 5.2174 1.06064V13.6872L6.57035 12.3276C6.9809 11.9159 7.64091 11.9189 8.04657 12.3362L8.04592 12.3355V12.3352ZM15.6902 3.16567L13.0557 0.518386C12.378 -0.171027 11.2749 -0.171027 10.6005 0.514077L7.96179 3.16567C7.55189 3.57732 7.54863 4.24884 7.95331 4.66547C8.35831 5.08276 9.01963 5.08542 9.42952 4.67409L10.7825 3.31449V15.9099C10.7825 16.4952 11.2491 16.9705 11.826 16.9705C12.4028 16.9705 12.8695 16.4952 12.8695 15.9099V3.31349L14.2224 4.67309C14.4256 4.8776 14.6913 4.98002 14.9564 4.98002C15.2216 4.98002 15.4942 4.87528 15.6986 4.66547C16.1036 4.24884 16.1001 3.57732 15.6902 3.16567Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconSort'
}
</script>
