<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.7919 5.68359H16.5003V5.50011C16.5003 2.46259 14.0377 0 11.0002 0C7.9627 0 5.50011 2.46259 5.50011 5.50011V5.68359H3.20854C1.4455 5.70464 0.0214848 7.12865 0 8.8917V16.9584C0.0244927 19.7325 2.26751 21.9759 5.04162 22H16.9584C19.7325 21.9755 21.9759 19.7325 22 16.9584V8.8917C21.9785 7.12865 20.5545 5.70464 18.7915 5.68316L18.7919 5.68359ZM8.25016 5.50011C8.25016 3.98113 9.48124 2.75005 11.0002 2.75005C12.5192 2.75005 13.7503 3.98113 13.7503 5.50011V5.68359H8.25016V5.50011ZM19.2504 16.9588C19.2624 18.2122 18.2565 19.2383 17.0031 19.2504C16.9885 19.2504 16.9734 19.2504 16.9588 19.2504H5.04162C3.7882 19.2624 2.76209 18.2565 2.75005 17.0031C2.75005 16.9885 2.75005 16.9734 2.75005 16.9588V8.89213C2.73544 8.65365 2.91678 8.44825 3.15526 8.43364C3.17287 8.43235 3.19049 8.43235 3.20811 8.43364H5.49968V9.62519C5.49968 10.3845 6.11543 11.0002 6.87471 11.0002C7.63398 11.0002 8.24973 10.3845 8.24973 9.62519V8.43364H13.7498V9.62519C13.7498 10.3845 14.3656 11.0002 15.1249 11.0002C15.8841 11.0002 16.4999 10.3845 16.4999 9.62519V8.43364H18.7915C19.0299 8.41903 19.2353 8.60036 19.2499 8.83885C19.2512 8.85646 19.2512 8.87408 19.2499 8.8917V16.9584L19.2504 16.9588Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconOrders'
}
</script>
