import { reactive } from "vue";
import { io } from "socket.io-client";
import store from "@/store";
import {getActiveCart} from "@/api/cart";

export const socketState = reactive({
    connected: false,
    updatingCart: false
});

// export const socket = io(URL);
export const socket = io(process.env.VUE_APP_NODE_API_BACKEND, {
    // withCredentials: true,
});

socket.on('connect', () => {
    socketState.connected = true;
});

socket.on('disconnect', () => {
    socketState.connected = false;
});

socket.on('eventFromBE2', async (msg) => {
    console.log('message')
    console.log(msg)

    const userId = store.getters.getUser?.id || null
    const deviceId = store.getters.getUser?.deviceId || null
    const uniqueId = store.getters.getUuid || null

    console.log(userId)
    console.log(deviceId)
    console.log(uniqueId)

    if (userId && deviceId && userId === msg.clientId && deviceId !== msg.deviceId) {
        console.log('update')
    }
})

socket.on('cartUpdated', async (msg) => {
    socketState.updatingCart = true
    console.log('message')
    console.log(msg)

    const userId = store.getters.getUser?.id || null
    const deviceId = store.getters.getUser?.deviceId || null
    const userAgent = msg.userAgent || null
    const resUniqueId = msg.uniqueId || null
    const localUserAgent = navigator.userAgent;
    let shouldUpdateCart = false
    const uniqueId = store.getters.getUuid || null

    if (uniqueId !== resUniqueId) {
        shouldUpdateCart = true
    }

    if (userAgent === null && deviceId !== parseInt(msg.deviceId)) {
        shouldUpdateCart = true
    }

    console.log('agents')
    console.log(localUserAgent)
    console.log(userAgent)
    console.log(uniqueId)
    console.log(shouldUpdateCart)

    if (userId && deviceId && userId === parseInt(msg.clientId) && shouldUpdateCart) {
        const cartResponse = await getActiveCart(userId)

        if (cartResponse?.data?.data) {
            await store.dispatch('saveUserCart', cartResponse.data.data)

            socketState.updatingCart = false
        } else if (cartResponse?.response?.status === 404) {
            await store.dispatch('saveUserCart', null)

            socketState.updatingCart = false
        } else {
            // borramos carrito
            await store.dispatch('saveUserCart', null)

            socketState.updatingCart = false
        }
    } else {
        socketState.updatingCart = false
    }
})