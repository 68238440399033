<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="113" height="26" viewBox="0 0 113 26" fill="none" v-bind="$attrs">
    <g clip-path="url(#clip0_140_2509)">
      <path d="M26.52 25.27C32.5 25.27 35.25 23.17 36 18.03L37.55 7.39H32.54L31.05 17.6C30.66 20.24 29.53 21.27 27.02 21.27C25.79 21.27 24.98 21.02 24.54 20.5C24.06 19.95 23.93 19.05 24.13 17.67L25.54 8.02999C25.55 7.92999 25.59 7.7 25.45 7.53C25.33 7.39 25.15 7.37 25.01 7.37H18.82L18.47 9.8L20.17 10.52L20.13 10.81L19.09 17.93C18.74 20.35 19.06 22.1 20.08 23.27C21.25 24.62 23.29 25.25 26.52 25.25V25.27Z" fill="currentColor"/>
      <path d="M70.35 24.86H75.98L76.84 22.4L75.19 21.71L75.24 21.35L76.33 13.89C76.67 11.53 76.36 9.81998 75.37 8.67998C74.38 7.53998 72.76 6.97998 70.4 6.97998C68.22 6.97998 66.33 7.55998 64.65 8.76998L64.36 8.96998L64.14 8.69998C63.23 7.57998 61.6 6.97998 59.43 6.97998C57.76 6.97998 56.41 7.30998 55.06 8.05998L54.58 8.32998L54.51 7.77998C54.5 7.68998 54.47 7.60998 54.41 7.54998C54.29 7.40998 54.11 7.38998 53.97 7.38998H47.95L47.6 9.81998L49.31 10.54L49.27 10.83L47.22 24.86H52.23L54.12 11.9L54.3 11.81C55.02 11.45 56.34 11.04 57.71 11.04C58.74 11.04 59.39 11.23 59.75 11.65C60.14 12.1 60.23 12.87 60.05 14.09L58.48 24.87H63.49L65.05 14.21C65.19 13.25 65.2 12.53 65.2 12.27V12.04L65.4 11.93C66.5 11.37 67.77 11.06 68.97 11.06C70 11.06 70.65 11.25 71.01 11.67C71.39 12.12 71.49 12.89 71.31 14.11L69.83 24.23C69.82 24.33 69.78 24.56 69.92 24.73C70.04 24.87 70.22 24.89 70.36 24.89L70.35 24.86Z" fill="currentColor"/>
      <path d="M111.69 7.11998C111.44 7.07998 110.74 6.97998 109.82 6.97998C108.14 6.97998 106.71 7.44998 105.45 8.41998L104.66 9.01998L104.85 8.04998C104.87 7.93998 104.9 7.71998 104.76 7.54998C104.64 7.40998 104.46 7.38998 104.32 7.38998L98.34 7.27998L97.95 9.81998L99.65 10.54L99.61 10.83L97.56 24.86H102.57L104.36 12.57L104.48 12.48C105.63 11.6 107.25 11.05 108.7 11.05C109.29 11.05 109.98 11.15 110.29 11.22L110.52 11.27L112 7.17998L111.68 7.12998L111.69 7.11998Z" fill="currentColor"/>
      <path d="M17.84 1.11006H17.69L8.85001 1.06006L8.34001 4.53006L12 5.66006L10.35 16.9801C9.92001 19.9501 8.60001 21.1001 5.66001 21.1001C4.48001 21.1001 3.30001 20.9501 2.17001 20.6601L1.37001 20.5001L0.62001 20.3501L0.0100098 24.5401L0.73001 24.6701L1.51001 24.8201C2.96001 25.1201 4.41001 25.2801 5.83001 25.2801C11.7 25.2801 14.68 22.8001 15.5 17.2401L17.84 1.11006Z" fill="currentColor"/>
      <path d="M38.68 24.86H44.74L45.17 22.4L43.54 21.71L43.58 21.34L46.59 0.66C46.61 0.55 46.64 0.32 46.5 0.16C46.38 0.02 46.2 0 46.06 0H39.87L39.52 2.43L41.23 3.15L41.19 3.44L38.16 24.21C38.14 24.38 38.13 24.56 38.25 24.71C38.37 24.85 38.55 24.87 38.69 24.87L38.68 24.86Z" fill="currentColor"/>
      <path d="M94.12 21.71L94.17 21.35L95.22 14.17C95.56 11.81 95.25 10.11 94.25 8.94998C93.09 7.60998 91.03 6.97998 87.77 6.97998C85.72 6.97998 83.67 7.24998 81.7 7.77998L81.05 7.94998L81.35 11.68C83.24 11.14 85.07 10.86 86.76 10.86C88.45 10.86 89.58 11.14 90.1 11.74C90.47 12.17 90.59 12.82 90.46 13.73L90.27 15L89.87 14.9C88.77 14.63 87.58 14.49 86.33 14.49C81.32 14.49 78.74 16.16 78.22 19.73C77.97 21.44 78.22 22.71 79.01 23.61C79.97 24.72 81.72 25.25 84.38 25.25C86.34 25.25 88.06 24.82 89.35 24.02L89.79 23.75L89.91 24.25C89.99 24.57 90.12 24.84 90.92 24.84H94.91L95.77 22.38L94.12 21.69V21.71ZM89.67 19.09C89.41 20.89 88.26 21.62 85.7 21.62C84.48 21.62 83.71 21.42 83.35 20.99C83.11 20.72 83.03 20.32 83.11 19.78C83.25 18.79 83.78 17.87 86.6 17.87C87.62 17.87 88.6 18 89.46 18.24L89.78 18.33L89.67 19.08V19.09Z" fill="currentColor"/>
    </g>
    <defs>
      <clipPath id="clip0_140_2509">
        <rect width="112.01" height="25.27" fill="currentColor"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconJulmar'
}
</script>
