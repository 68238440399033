<template>
  <svg
    width="19"
    height="21"
    viewBox="0 0 19 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.1357 19.2495H14.8195C14.8195 19.2495 18.7239 14.8178 18.7506 14.7748C19.0652 14.2666 19.0814 13.6259 18.7948 13.101C18.5073 12.576 17.9613 12.2495 17.3698 12.2495H13.8188C13.342 12.2495 12.9554 12.6408 12.9554 13.1243C12.9554 13.6079 13.3424 13.9992 13.8188 13.9992H17.135C17.135 13.9992 13.2306 18.4308 13.2039 18.4739C12.8894 18.9821 12.8732 19.6227 13.1598 20.1477C13.4472 20.6727 13.9933 20.9992 14.5847 20.9992H18.1357C18.6126 20.9992 18.9992 20.6079 18.9992 20.1243C18.9992 19.6408 18.6122 19.2495 18.1357 19.2495ZM18.9992 3.06258V7.87485C18.9992 8.35882 18.6122 8.74969 18.1357 8.74969C17.6593 8.74969 17.2723 8.35841 17.2723 7.87485V6.12474H14.6815V7.87485C14.6815 8.35882 14.2945 8.74969 13.818 8.74969C13.3416 8.74969 12.9546 8.35841 12.9546 7.87485V3.06258C12.9546 1.374 14.3103 0 15.9773 0C17.6443 0 19 1.37359 19 3.06258H18.9992ZM17.2719 4.37505V3.06258C17.2719 2.33908 16.6906 1.7501 15.9765 1.7501C15.2624 1.7501 14.6811 2.33908 14.6811 3.06258V4.37505H17.2719ZM10.1099 5.50624C10.4476 5.16418 10.4476 4.6113 10.1099 4.26882L6.40269 0.512685C6.06589 0.171442 5.62383 0 5.18178 0C4.73973 0 4.29727 0.170622 3.96047 0.511865L0.253209 4.26923C-0.0844031 4.6113 -0.0844031 5.16418 0.253209 5.50665C0.590821 5.84871 1.13651 5.84871 1.47452 5.50665L4.31832 2.62536V20.1252C4.31832 20.6091 4.70451 21 5.18178 21C5.65905 21 6.04524 20.6087 6.04524 20.1252V2.62495L8.88904 5.50624C9.22665 5.8483 9.77234 5.8483 10.1104 5.50624H10.1099Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconTextUp'
}
</script>
