<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none" v-bind="$attrs">
    <path d="M11.502 0C5.18366 0 0 5.18366 0 11.502C0 17.8203 5.18366 23.004 11.502 23.004C17.8203 23.004 23.004 17.8203 23.004 11.502C23.004 5.18366 17.8163 0 11.502 0ZM17.2191 15.3121L15.3121 17.2191L11.502 13.409L7.69188 17.2191L5.78484 15.3121L9.59494 11.502L5.78484 7.69188L7.69188 5.78484L11.502 9.59494L15.3121 5.78484L17.2191 7.69188L13.409 11.502L17.2191 15.3121Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconClose'
}
</script>
