<template>
  <svg
    width="12"
    height="6"
    viewBox="0 0 12 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2041_3846)">
      <path
        d="M6.00377 5.99988C5.78624 5.99988 5.57597 5.92996 5.4092 5.78377L0.246666 1.25835C-0.0796179 0.972333 -0.0796179 0.501994 0.246666 0.215978C0.57295 -0.0700394 1.10951 -0.0700394 1.43579 0.215978L6.00377 4.22021L10.5645 0.215978C10.8908 -0.0700394 11.4273 -0.0700394 11.7536 0.215978C12.0799 0.501994 12.0799 0.972333 11.7536 1.25835L6.59108 5.78377C6.42431 5.92996 6.21404 5.99988 5.99652 5.99988H6.00377Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2041_3846">
        <rect width="12" height="6" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconSortDown'
}
</script>
