<template>
  <div class="w-full min-h-screen bg-white flex">
    <div class="w-2/3 h-screen">
      <img
        src="/images/login.jpg"
        alt="Julmar"
        class="w-full h-full object-cover"
      />
    </div>
    <div class="w-1/3 p-20 flex flex-col">
      <div class="flex flex-col flex-1 items-center justify-center">
        <form
          @submit.prevent="register"
          class="flex flex-col w-[388px] gap-[32px]"
        >
          <div class="flex items-center justify-center">
            <img src="/images/julmar.svg" alt="Julmar" class="w-[192px] h-11" />
          </div>
          <div class="flex flex-col gap-3">
            <base-input
              id="clientCode"
              type="text"
              v-model="clientCode"
              placeholder="Código Cliente"
              required
              @keydown.enter.prevent="register"
              @blur="onBlur"
              :error="this.v$.clientCode.$errors[0]?.$message"
            />
            <base-input
              id="name"
              type="text"
              v-model="name"
              placeholder="Nombre de Empresa"
              required
              @keydown.enter.prevent="register"
              @blur="onBlur"
              :error="this.v$.name.$errors[0]?.$message"
            />
            <base-input
              id="clientCode"
              type="text"
              v-model="clientCompany"
              placeholder="Razón Social"
              required
              @keydown.enter.prevent="register"
              @blur="onBlur"
              :error="this.v$.clientCompany.$errors[0]?.$message"
            />
            <base-input
              id="direccion"
              type="text"
              v-model="direccion"
              placeholder="Dirección"
              required
              @keydown.enter.prevent="register"
              @blur="onBlur"
              :error="this.v$.direccion.$errors[0]?.$message"
            />
            <base-input
              id="cellPhone"
              type="text"
              v-model="cellPhone"
              placeholder="Teléfono/Celular"
              required
              @keydown.enter.prevent="register"
              @blur="onBlur"
              :error="this.v$.cellPhone.$errors[0]?.$message"
            />
            <base-input
              id="cuit"
              type="text"
              v-model="cuit"
              placeholder="C.U.I.T."
              required
              @keydown.enter.prevent="register"
              @blur="onBlur"
              :error="this.v$.cuit.$errors[0]?.$message"
            />
            <base-input
              id="clientCode"
              type="email"
              v-model="email"
              placeholder="jhprado@live.com"
              required
              @keydown.enter.prevent="register"
              @blur="onBlur"
              :error="this.v$.email.$errors[0]?.$message"
            />
            <base-input
              id="password"
              type="password"
              v-model="password"
              placeholder="******"
              required
              @keydown.enter.prevent="register"
              @blur="onBlur"
              :error="this.v$.password.$errors[0]?.$message"
            />
            <base-input
              id="confirmPassword"
              type="password"
              v-model="confirmPassword"
              placeholder="Re-ingresar Contraseña"
              required
              @keydown.enter.prevent="register"
              @blur="onBlur"
              :error="this.v$.confirmPassword.$errors[0]?.$message"
            />
            <button
              type="submit"
              class="flex items-center justify-center bg-base-yellow hover:bg-[#b78c46] rounded-[5px] w-[388px] h-[45px] font-bold text-lg text-white"
            >
              Registrar
            </button>
          </div>
        </form>
      </div>
      <div class="flex flex-col flex-0 gap-5">
        <span class="text-black text-base"
          >Descargar la App para Celulares</span
        >
        <div class="flex gap-4">
          <img
            src="/images/app-store.svg"
            alt="App Store"
            class="w-[164px] h-[89px]"
          />
          <img
            src="/images/google-play.svg"
            alt="Google Play"
            class="w-[164px] h-[89px]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/Base/BaseInput.vue'
import useVuelidate from '@vuelidate/core'
import { email, required, helpers } from '@vuelidate/validators'
import smoothScrollMixin from '@/mixins/scrollToUpMixin'

export default {
  name: 'RegisterView',
  components: { BaseInput },
  mixins: [smoothScrollMixin],
  data() {
    return {
      loading: false,
      isDisabled: false,
      v$: useVuelidate(),
      name: '',
      clientCode: '',
      clientCompany: '',
      direccion: '',
      cellPhone: '',
      cuit: '',
      email: '',
      password: '',
      confirmPassword: ''
    }
  },
  methods: {
    onBlur(e) {
      this.v$[e.target.id].$touch()
    },
    async register() {
      this.v$.$touch()

      if (this.v$.$invalid) {
        return
      }

      console.log('register')
    }
  },
  validations: {
    name: {
      required: helpers.withMessage('El nombre es requerido.', required)
    },
    clientCode: {
      required: helpers.withMessage(
        'El código de cliente es requerido.',
        required
      )
    },
    clientCompany: {
      required: helpers.withMessage('La razón social es requerida.', required)
    },
    direccion: {
      required: helpers.withMessage('La dirección es requerida.', required)
    },
    cellPhone: {
      required: helpers.withMessage(
        'El teléfono/celular es requerido.',
        required
      )
    },
    cuit: {
      required: helpers.withMessage('El C.U.I.T. es requerido.', required)
    },
    email: {
      email: helpers.withMessage('Por favor ingrese un email válido.', email),
      required: helpers.withMessage('El email es requerido.', required)
    },
    password: {
      required: helpers.withMessage('El password es requerido.', required)
    },
    confirmPassword: {
      required: helpers.withMessage(
        'La confirmación de la contraseña es requerida.',
        required
      )
    }
  },
  beforeCreate() {
    if (this.$store.getters.getUser !== null) {
      this.$router.push('/')
    }
  }
}
</script>
