<template>
  <svg
    width="19"
    height="21"
    viewBox="0 0 19 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.1357 19.2499H14.8195C14.8195 19.2499 18.7239 14.8181 18.7506 14.7751C19.0652 14.2669 19.0814 13.6262 18.7948 13.1012C18.5073 12.5762 17.9613 12.2497 17.3698 12.2497H13.8188C13.342 12.2497 12.9554 12.641 12.9554 13.1246C12.9554 13.6082 13.3424 13.9995 13.8188 13.9995H17.135C17.135 13.9995 13.2306 18.4312 13.2039 18.4743C12.8894 18.9824 12.8732 19.6231 13.1598 20.1481C13.4472 20.6731 13.9933 20.9996 14.5847 20.9996H18.1357C18.6126 20.9996 18.9992 20.6083 18.9992 20.1247C18.9992 19.6412 18.6122 19.2499 18.1357 19.2499ZM18.9992 3.06264V7.875C18.9992 8.35898 18.6122 8.74986 18.1357 8.74986C17.6593 8.74986 17.2723 8.35857 17.2723 7.875V6.12486H14.6815V7.875C14.6815 8.35898 14.2945 8.74986 13.818 8.74986C13.3416 8.74986 12.9546 8.35857 12.9546 7.875V3.06264C12.9546 1.37402 14.3103 0 15.9773 0C17.6443 0 19 1.37361 19 3.06264H18.9992ZM17.2719 4.37514V3.06264C17.2719 2.33912 16.6906 1.75014 15.9765 1.75014C15.2624 1.75014 14.6811 2.33912 14.6811 3.06264V4.37514H17.2719ZM0.253209 15.4937C-0.0844031 15.8357 -0.0844031 16.3886 0.253209 16.7311L3.96047 20.4873C4.29727 20.8286 4.73932 21 5.18178 21C5.62424 21 6.06629 20.8294 6.4031 20.4881L10.1104 16.7307C10.448 16.3886 10.448 15.8357 10.1104 15.4932C9.77274 15.1508 9.22706 15.1512 8.88904 15.4932L6.04524 18.3746V0.874863C6.04524 0.390879 5.65905 0 5.18178 0C4.70451 0 4.31832 0.391289 4.31832 0.874863V18.375L1.47452 15.4937C1.13691 15.1516 0.591226 15.1516 0.253209 15.4937Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconTextDown'
}
</script>
