<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1273_2486)">
      <path
        d="M7 0C3.13996 0 0 3.13996 0 7C0 10.86 3.13996 14 7 14C10.86 14 14 10.8595 14 7C14 3.14051 10.86 0 7 0ZM7 12.9156C3.73857 12.9156 1.08443 10.262 1.08443 7C1.08443 3.73803 3.73857 1.08443 7 1.08443C10.2614 1.08443 12.9156 3.73803 12.9156 7C12.9156 10.262 10.262 12.9156 7 12.9156Z"
        fill="currentColor"
      />
      <path
        d="M9.71107 6.40883H7.54222V4.23998C7.54222 3.94068 7.29984 3.69775 6.99999 3.69775C6.70014 3.69775 6.45777 3.94068 6.45777 4.23998V6.40883H4.28892C3.98906 6.40883 3.74669 6.65175 3.74669 6.95106C3.74669 7.25036 3.98906 7.49328 4.28892 7.49328H6.45777V9.66214C6.45777 9.96144 6.70014 10.2044 6.99999 10.2044C7.29984 10.2044 7.54222 9.96144 7.54222 9.66214V7.49328H9.71107C10.0109 7.49328 10.2533 7.25036 10.2533 6.95106C10.2533 6.65175 10.0109 6.40883 9.71107 6.40883Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1273_2486">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconAdd'
}
</script>
