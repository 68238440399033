<template>
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.379 5.30233C15.9647 4.27709 15.342 3.31586 14.513 2.48698C13.6841 1.65799 12.7229 1.03527 11.6976 0.620997C10.6723 0.206589 9.58294 0 8.49407 0C7.40674 0 6.31937 0.207486 5.29612 0.622358C4.273 1.03706 3.31406 1.65969 2.48691 2.48698C1.65966 3.3141 1.037 4.27304 0.622292 5.29619C0.207486 6.31937 0 7.40671 0 8.49404C0 9.5829 0.206589 10.6722 0.62093 11.6976C1.03521 12.7229 1.6579 13.6841 2.48691 14.513C3.31579 15.342 4.27702 15.9647 5.30226 16.379C6.32764 16.7934 7.41697 16.9999 8.50583 16.9999C9.59316 16.9999 10.6805 16.7924 11.7038 16.3776C12.7269 15.9629 13.6858 15.3403 14.513 14.513C15.3403 13.6858 15.9629 12.7269 16.3776 11.7038C16.7925 10.6806 17 9.59323 17 8.50583C17 7.41703 16.7934 6.32767 16.379 5.30233ZM15.327 11.2778C14.9676 12.1638 14.4298 12.9927 13.7113 13.7113C12.9927 14.4298 12.1637 14.9676 11.2778 15.3269C10.392 15.6861 9.44903 15.8662 8.50586 15.8662C7.56034 15.8662 6.61505 15.6867 5.72716 15.3278C4.83918 14.9688 4.00857 14.4311 3.2887 13.7113C2.56889 12.9914 2.03116 12.1609 1.67221 11.2728C1.31335 10.385 1.13385 9.43963 1.13388 8.49411C1.13388 7.55094 1.31398 6.60804 1.67314 5.72222C2.03243 4.83626 2.57022 4.00734 3.28876 3.28873C4.00738 2.57022 4.83629 2.03243 5.72228 1.6731C6.60811 1.31391 7.55104 1.13385 8.49421 1.13385C9.43973 1.13385 10.385 1.31331 11.2729 1.67221C12.1609 2.03123 12.9915 2.56892 13.7114 3.2887C14.4312 4.00861 14.9689 4.83918 15.3279 5.72716C15.6867 6.61505 15.8662 7.56034 15.8662 8.50586C15.8662 9.44899 15.6862 10.3919 15.327 11.2778Z"
      fill="currentColor"
    />
    <path
      d="M12.0301 4.9691C11.8135 4.75249 11.4623 4.75249 11.2457 4.9691L4.96984 11.2449C4.75323 11.4616 4.75323 11.8128 4.96984 12.0294C5.18649 12.2461 5.53768 12.2461 5.75433 12.0294L12.0302 5.75356C12.2468 5.53694 12.2468 5.18569 12.0301 4.9691Z"
      fill="currentColor"
    />
    <path
      d="M8.19317 4.55092C8.11189 4.35024 7.9891 4.16148 7.82764 4.00012C7.6663 3.83865 7.47754 3.7159 7.27683 3.63458C7.07602 3.5532 6.86335 3.5127 6.65089 3.5127C6.43842 3.5127 6.22576 3.5532 6.02494 3.63458C5.82426 3.71586 5.63551 3.83865 5.47414 4.00012C5.31267 4.16142 5.18992 4.35021 5.10861 4.55092C5.02723 4.75173 4.98672 4.9644 4.98672 5.17687C4.98672 5.38933 5.02723 5.602 5.10861 5.80281C5.18989 6.00349 5.31271 6.19225 5.47414 6.35362C5.63547 6.51508 5.82423 6.63783 6.02494 6.71915C6.22576 6.80053 6.43842 6.84104 6.65089 6.84104C6.86335 6.84104 7.07602 6.80053 7.27683 6.71915C7.47751 6.63787 7.66627 6.51508 7.82764 6.35362C7.9891 6.19228 8.11186 6.00352 8.19317 5.80281C8.27455 5.602 8.31506 5.38933 8.31506 5.17687C8.31506 4.9644 8.27458 4.75173 8.19317 4.55092ZM7.16504 5.38588C7.13801 5.45229 7.09833 5.51381 7.04315 5.56913C6.98787 5.62431 6.92634 5.66399 6.85993 5.69102C6.79363 5.71794 6.72237 5.73159 6.65092 5.73159C6.57944 5.73159 6.50821 5.71791 6.44191 5.69102C6.3755 5.66399 6.31398 5.62431 6.25869 5.56913C6.20351 5.51384 6.16383 5.45232 6.1368 5.38591C6.10988 5.31961 6.09623 5.24835 6.09623 5.1769C6.0962 5.10541 6.10988 5.03416 6.13677 4.96785C6.1638 4.90145 6.20348 4.83992 6.25866 4.78464C6.31398 4.72945 6.37547 4.68974 6.44191 4.66275C6.50821 4.63582 6.57947 4.62218 6.65092 4.62218C6.72241 4.62218 6.79363 4.63586 6.85993 4.66275C6.92634 4.68978 6.98787 4.72945 7.04315 4.78464C7.09833 4.83992 7.13801 4.90145 7.16504 4.96785C7.19197 5.03416 7.20561 5.10541 7.20561 5.17687C7.20561 5.24832 7.19193 5.31957 7.16504 5.38588Z"
      fill="currentColor"
    />
    <path
      d="M12.2611 10.8322C12.1798 10.6315 12.057 10.4427 11.8955 10.2814C11.7342 10.1199 11.5454 9.99715 11.3447 9.91583C11.1439 9.83445 10.9312 9.79395 10.7188 9.79395C10.5063 9.79395 10.2936 9.83445 10.0928 9.91583C9.89216 9.99712 9.7034 10.1199 9.54203 10.2814C9.38057 10.4427 9.25781 10.6315 9.1765 10.8322C9.09512 11.033 9.05461 11.2456 9.05461 11.4581C9.05461 11.6706 9.09512 11.8832 9.1765 12.0841C9.25778 12.2847 9.38057 12.4735 9.54203 12.6349C9.70337 12.7963 9.89212 12.9191 10.0928 13.0004C10.2936 13.0818 10.5063 13.1223 10.7188 13.1223C10.9312 13.1223 11.1439 13.0818 11.3447 13.0004C11.5454 12.9191 11.7342 12.7963 11.8955 12.6349C12.057 12.4735 12.1797 12.2848 12.2611 12.0841C12.3424 11.8832 12.383 11.6706 12.383 11.4581C12.383 11.2456 12.3425 11.033 12.2611 10.8322ZM11.233 11.6671C11.2059 11.7335 11.1663 11.795 11.1111 11.8503C11.0558 11.9055 10.9943 11.9451 10.9279 11.9722C10.8616 11.9991 10.7903 12.0127 10.7188 12.0127C10.6474 12.0127 10.5761 11.9991 10.5098 11.9722C10.4434 11.9451 10.3819 11.9055 10.3266 11.8503C10.2714 11.795 10.2318 11.7335 10.2047 11.6671C10.1778 11.6008 10.1641 11.5295 10.1642 11.4581C10.1642 11.3866 10.1778 11.3153 10.2047 11.249C10.2318 11.1826 10.2714 11.1211 10.3266 11.0658C10.3819 11.0106 10.4434 10.971 10.5099 10.9439C10.5762 10.917 10.6474 10.9034 10.7189 10.9034C10.7904 10.9034 10.8616 10.917 10.9279 10.9439C10.9942 10.971 11.0557 11.0106 11.1111 11.0658C11.1663 11.1211 11.2059 11.1826 11.233 11.249C11.2599 11.3153 11.2735 11.3866 11.2735 11.4581C11.2735 11.5295 11.2599 11.6008 11.233 11.6671Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconPercentage'
}
</script>
