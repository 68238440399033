<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
    <g clip-path="url(#clip0_140_1571)">
      <path d="M22.41 19.48L17.13 14.2C18.03 12.77 18.55 11.08 18.55 9.27C18.55 4.15 14.4 0 9.28 0C4.16 0 0 4.15 0 9.28C0 14.41 4.15 18.56 9.28 18.56C11.25 18.56 13.08 17.94 14.58 16.9L19.8 22.12C20.16 22.48 20.64 22.66 21.11 22.66C21.58 22.66 22.06 22.48 22.42 22.12C23.14 21.4 23.14 20.22 22.42 19.5L22.41 19.48ZM9.27 15.55C5.81 15.55 3 12.74 3 9.28C3 5.82 5.81 3.01 9.27 3.01C12.73 3.01 15.54 5.82 15.54 9.28C15.54 12.74 12.73 15.55 9.27 15.55Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_140_1571">
        <rect width="22.96" height="22.65" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconSearch'
}
</script>
